<template>
  <div>
    <div class="my-6">
      <v-row>
        <v-col cols="12">
          <v-card class="px-2 stat-card rounded-md d-inline-flex pt-2 pb-1 overflow-hidden" width="100%">
            <v-list-item>
              <v-list-item-content class="pt-0">
                <v-list-item-subtitle class="txt-5E6 mb-2 font-weight-medium">Stocks Value</v-list-item-subtitle>
                <v-list-item-title class="maintext--text font-weight-medium"
                  ><span id="holdstatval">{{ statholding.stockvalue ? statholding.stockvalue : "0.00" }}</span></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="holdingtype == 0">
              <v-list-item-content class="pt-0">
                <v-list-item-subtitle class="txt-5E6 mb-2 font-weight-medium">Day Change</v-list-item-subtitle>
                <v-list-item-title class="maintext--text font-weight-medium"
                  ><span id="holdstatdpnl">{{ statholding.d_pnl > 0 ? statholding.d_pnl : "0.00" }}</span
                  >&nbsp;
                  <span class="fs-13" id="holdstatdpnlcclr" :class="statholding.d_cpnl > 0 ? 'maingreen--text' : statholding.d_cpnl < 0 ? 'mainred--text' : 'subtext--text'"
                    ><span id="holdstatdpnlc"> {{ statholding.d_cpnl > 0 || statholding.d_cpnl < 0 ? statholding.d_cpnl : "0.00" }}</span
                    >%
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content class="pt-0">
                <v-list-item-subtitle class="txt-5E6 mb-2 font-weight-medium">Invested</v-list-item-subtitle>
                <v-list-item-title class="maintext--text font-weight-medium" id="holdstatinv">{{ statholding.invested ? statholding.invested : "0.00" }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content class="pt-0">
                <v-list-item-subtitle class="txt-5E6 mb-2 font-weight-medium">Profit/Loss</v-list-item-subtitle>
                <v-list-item-title class="maintext--text font-weight-medium"
                  ><span id="holdstatpnl">{{ statholding.pnl > 0 ? statholding.pnl : "0.00" }}</span
                  >&nbsp;
                  <span class="fs-13" id="holdstatpnlcclr" :class="statholding.cpnl > 0 ? 'maingreen--text' : statholding.cpnl < 0 ? 'mainred--text' : 'subtext--text'"
                    ><span id="holdstatpnlc"> {{ statholding.cpnl > 0 || statholding.cpnl < 0 ? statholding.cpnl : "0.00" }}</span
                    >%
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="pb-0">
                <v-list-item-subtitle class="txt-5E6 mb-0 font-weight-medium">Stock Position</v-list-item-subtitle>
                <v-chip-group row>
                  <v-chip class="mr-2 rounded-lg" color="secgreen" text-color="maingreen">
                    <span class="caption" id="holdstatP"> {{ statholding.positive ? statholding.positive.length : "0" }} </span>&nbsp;Positive
                  </v-chip>
                  <v-chip height="16" class="mr-2 rounded-lg" color="secred" text-color="mainred">
                    <span class="caption" id="holdstatN"> {{ statholding.negative ? statholding.negative.length : "0" }} </span>&nbsp;Negative
                  </v-chip>
                </v-chip-group>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <!-- <v-col cols="4" class="d-none">
          <v-card class="px-2 py-2 stat-card rounded-md d-inline-flex pt-2 pb-3" width="100%">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle class="txt-5E6 mb-2 font-weight-medium">Today's High</v-list-item-subtitle>
                <v-list-item-title class="maintext--text font-weight-medium">{{ statholding.hp ? statholding.hp : '0.00' }}
                  <span :class="statholding.chp > 0 ? 'maingreen--text'  : statholding.chp < 0 ? 'mainred--text'  : 'subtext--text'">
                      {{ statholding.chp ? statholding.chp : '0.00' }}%
                    </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle class="txt-5E6 mb-2 font-weight-medium">Today's Low</v-list-item-subtitle>
                <v-list-item-title class="maintext--text font-weight-medium">{{ statholding.lp ? statholding.lp : '0.00' }}
                  <span :class="statholding.clp > 0 ? 'maingreen--text'  : statholding.clp < 0 ? 'mainred--text'  : 'subtext--text'">
                      {{ statholding.clp ? statholding.clp : '0.00' }}%
                    </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col> -->
      </v-row>

      <v-toolbar flat dense class="tool-sty my-6 pl-4 crd-trn">
        <v-tabs fixed color="primary" @change="optionChainDataParse(), holdingtype == 0 ? (exchtype = 'all') : (exchtype = 'mf'), (opensearch = null)" v-model="holdingtype">
          <v-tab class="font-weight-bold subtitle-1 mb-0 text-none">Stocks ({{ holds }})</v-tab>
          <v-tab class="font-weight-bold subtitle-1 mb-0 text-none">Mutual Funds ({{ mfholds }})</v-tab>
          <v-tabs-slider color="primary"></v-tabs-slider>
        </v-tabs>
        <v-spacer></v-spacer>

        <v-text-field style="max-width: 220px" v-model="opensearch" hide-details prepend-inner-icon="mdi-magnify" label="Search on holding" class="rounded-pill" dense flat solo background-color="secbg"></v-text-field>

        <v-select
          v-if="holdingtype == 0"
          style="max-width: 160px"
          v-model="exchtype"
          hide-details
          append-icon="mdi-chevron-down"
          item-text="txt"
          item-value="val"
          prepend-inner-icon="mdi-playlist-check"
          class="rounded-pill ml-4"
          dense
          flat
          solo
          background-color="secbg"
          :items="dashitems"
          label="Filter"
        ></v-select>
        <v-btn v-if="holdingtype == 0 && edisbtn" @click="setdoEdis()" class="elevation-0 rounded-pill font-weight-bold text-none ml-4" color="primary">E-DIS</v-btn>
        <v-btn v-else-if="holdingtype == 0" target="_blank" :href="`https://profile.mynt.in/deposltory/?uid=${uid}&token=${stoken}`" class="elevation-0 rounded-pill font-weight-bold text-none ml-4" color="primary">Enable POA</v-btn>

        <v-icon :disabled="loading" class="ml-3 cursor-p" @click="getHoldingbook()" color="maintext" size="24">mdi-reload</v-icon>
      </v-toolbar>
      <!-- 
        disable-sort
        must-sort
        :sort-by="['']"
        :sort-desc="[false]" 
        :custom-sort="customSort"
        @update:sort-by="preventSorting"
        
        -->
      <v-data-table
        must-sort
        :custom-sort="customSort"
        fixed-header
        hide-default-footer
        :loading="loading"
        class="mt-3 rounded-lg overflow-y-auto"
        style="border-radius: 4px; border: 1px solid var(--outline)"
        height="480px"
        :headers="holdingtype == 0 ? holdingheader : holdingMHheader"
        :search="opensearch"
        :items="holdingdata"
        :items-per-page="-1"
      >
        <template v-slot:body="{items, headers}">
          <tbody v-if="holdingdata && holdingdata.length > 0">
            <tr @click="setHoldingrowdata(item)" class="table-row" v-for="(item, o) in items" :key="o">
              <!-- <td> <v-chip small class="table-hov-prd" text-color="#666"
                  style="border-radius: 5px; padding: 10px 8px !important;">
                  <span class="font-weight-medium fs-12">{{ item.s_prdt_ali }}</span>
                </v-chip></td> -->
              <td class="pos-rlt">
                <p class="font-weight-medium maintext--text mb-0 table-hov-text mr-4" :class="item.exchs == 'MF' ? '' : 'ws-p'" :style="`min-width:${item.exchs == 'MF' ? '100px' : ''};`">
                  {{ item.exchs == "MF" ? item.name : item.tsym ? item.tsym : "" }}
                  <span class="ml-1 subtext--text fs-10">{{ item.exchs ? item.exchs : item.exch ? item.exch : "" }}</span>
                </p>
                <div v-if="item.exchs == 'MF'" @click.stop class="pos-abs table-hov" style="top: 9px; right: 0">
                  <v-btn @click="switchBus.$emit('menudialog', 'mforder', 'redem', item)" small class="elevation-0 rounded-pill text-none primary--text font-weight-bold" color="secbg">Redeem</v-btn>
                </div>
                <div v-if="item.action" @click.stop class="pos-abs table-hov" style="top: 15px; right: 0">
                  <v-btn @click="(holdingdrawer = false), switchBus.$emit('menudialog', 'order', item.token, item.exch, item.tsym, 'b')" min-width="20px" color="maingreen" class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small> B </v-btn>
                  <v-btn @click="(holdingdrawer = false), switchBus.$emit('menudialog', 'order', item.token, item.exch, item.tsym, 's')" min-width="20px" color="mainred" class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small> S </v-btn>
                  <v-btn @click="setSSDtab('chart', item.token, item.exch, item.tsym)" style="border: 1px solid var(--outline)" min-width="20px" color="mainbg" class="px-0 font-weight-bold elevation-0 mr-1" x-small>
                    <v-icon size="18" color="maintext">mdi-chart-line-variant</v-icon>
                  </v-btn>
                  <v-tooltip top color="black">
                    <template v-slot:activator="{on, attrs}">
                      <div v-bind="attrs" v-on="on">
                        <v-btn @click="setSSDtab('exit-order', item.token, item.exch, item.tsym, item.netqty < 0 ? 'b' : 's', item)" style="border: 1px solid var(--outline)" min-width="20px" color="mainbg" class="px-0 font-weight-bold elevation-0 mr-1" x-small>
                          <v-icon size="18" color="maintext">mdi-close</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>Exit</span>
                  </v-tooltip>
                  <v-menu close-on-click absolute offset-y class="table-menu">
                    <template v-slot:activator="{on, attrs}">
                      <v-btn v-bind="attrs" v-on="on" style="border: 1px solid var(--outline)" min-width="20px" color="mainbg" class="px-0 font-weight-bold elevation-0 mr-1" x-small>
                        <v-icon size="20" color="maintext">mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <v-card class="table-menu-list">
                      <v-list dense>
                        <div v-for="(m, k) in menulist" :key="k">
                          <v-list-item @click="m.type != '' ? setSSDtab(m.type, item.token, item.exch, item.tsym, item.netqty < 0 ? 'b' : 's', item) : setHoldingrowdata(item)" class="pl-3 pr-6">
                            <v-list-item-icon class="mr-3 text-center">
                              <img v-if="m.icon > 2" width="20px" class="pl-1" :src="require(`@/assets/orderbook/${m.icon}.svg`)" />
                              <v-icon v-else color="#506D84">{{ m.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title class="subline--text font-weight-medium fs-14">{{ m.name }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider v-if="m.hr" class="mx-3"></v-divider>
                        </div>
                      </v-list>
                    </v-card>
                  </v-menu>
                </div>
              </td>

              <td>
                <v-chip v-if="item.plgqty > 0" small class="table-hov-prd" color="secbg" style="border-radius: 5px; padding: 10px 8px !important">
                  <v-icon color="primary" size="12">mdi-lock</v-icon>
                  <span class="font-weight-medium fs-12 primary--text">{{ item.plgqty }}</span>
                </v-chip>
              </td>
              <td v-if="item.exchs != 'MF'">
                <v-chip v-if="item.btstqty > 0" small color="#FFD8B4" text-color="#E8862A" :style="`border: 1px solid ${item.btstqty > 0 ? '#C1E7BA' : item.btstqty < 0 ? '#FFCDCD' : '#DDD'}; border-radius: 5px; padding: 10px 8px !important;`">
                  <span class="font-weight-medium fs-12">{{ `T1 ${item.btstqty}` }}</span>
                </v-chip>
              </td>
              <td>
                <v-chip
                  small
                  :color="item.netqty > 0 ? 'secgreen' : item.netqty < 0 ? 'secred' : 'secbg'"
                  :text-color="item.netqty > 0 ? 'maingreen' : item.netqty < 0 ? 'mainred' : 'subtext'"
                  :style="`border: 1px solid ${item.netqty > 0 ? '#C1E7BA' : item.netqty < 0 ? '#FFCDCD' : '#DDD'}; border-radius: 5px; padding: 10px 8px !important;`"
                >
                  <span class="font-weight-medium fs-12">{{ item.netqty > 0 ? `+${item.netqty}` : item.netqty < 0 ? `${item.netqty}` : "0" }}</span>
                </v-chip>
              </td>
              <!-- <td> <v-chip small :color="item.salqty > 0 ? '#ECF8F1' : item.salqty < 0 ? '#FCF3F3' : '#F1F3F8'"
                  :text-color="item.salqty > 0 ? 'maingreen--text'  : item.salqty < 0 ? 'mainred--text'  : 'subtext--text'"
                  :style="`border: 1px solid ${item.salqty > 0 ? '#C1E7BA' : item.salqty < 0 ? '#FFCDCD' : '#DDD'}; border-radius: 5px; padding: 10px 8px !important;`">
                  <span class="font-weight-medium fs-12">{{ item.salqty > 0 ? `+${item.salqty}` : item.salqty < 0 ?
                    `${item.salqty}` : '0' }}</span>
                </v-chip></td> -->
              <!-- <td> <v-chip small :color="item.plgqty > 0 ? '#ECF8F1' : item.plgqty < 0 ? '#FCF3F3' : '#F1F3F8'"
                  :text-color="item.plgqty > 0 ? 'maingreen--text'  : item.plgqty < 0 ? 'mainred--text'  : 'subtext--text'"
                  :style="`border: 1px solid ${item.plgqty > 0 ? '#C1E7BA' : item.plgqty < 0 ? '#FFCDCD' : '#DDD'}; border-radius: 5px; padding: 10px 8px !important;`">
                  <span class="font-weight-medium fs-12">{{ item.plgqty > 0 ? `+${item.plgqty}` : item.plgqty < 0 ?
                    `${item.plgqty}` : '0' }}</span>
                </v-chip></td> -->
              <td class="text-right font-weight-medium">
                <p class="font-weight-medium maintext--text mb-0">{{ item.upldprc ? Math.abs(item.upldprc).toFixed(2).toLocaleString() : "" }}</p>
              </td>
              <td class="text-right font-weight-medium">
                <p class="font-weight-medium maintext--text mb-0">
                  <span v-if="item.exchs != 'MF'" :id="`hold${item.token}ltp`"> {{ item.ltp ? item.ltp : "0.00" }}</span>
                  <span v-else> {{ item.ltp ? item.ltp : "0.00" }}</span>
                </p>
              </td>
              <td class="text-right font-weight-medium">
                <p class="font-weight-medium maintext--text mb-0">
                  <span v-if="item.exchs != 'MF'" :id="`hold${item.token}inv`">{{ item.inv ? Math.abs(item.inv).toLocaleString() : "0.00" }}</span>
                  <span v-else>{{ item.inv ? Math.abs(item.inv).toLocaleString() : "0.00" }}</span>
                </p>
              </td>
              <td class="text-right font-weight-medium">
                <p class="font-weight-medium maintext--text mb-0">
                  <span v-if="item.exchs != 'MF'" :id="`hold${item.token}curr`">{{ item.curr ? Math.abs(item.curr).toLocaleString() : "0.00" }}</span>
                  <span v-else>{{ item.curr ? Math.abs(item.curr).toLocaleString() : "0.00" }}</span>
                </p>
              </td>

              <td v-if="item.exchs != 'MF'" class="text-right font-weight-medium">
                <p class="font-weight-medium maintext--text mb-0" :id="`hold${item.token}d_pnl`">
                  {{ item.exchs != "MF" ? (item.d_pnl ? item.d_pnl : "0.00") : "-" }}
                </p>
              </td>
              <td v-if="item.exchs != 'MF'" class="text-right font-weight-medium">
                <p class="font-weight-medium mb-0" :id="`hold${item.token}d_cpnlclr`" :class="item.d_cpnl > 0 ? 'maingreen--text' : item.d_cpnl < 0 ? 'mainred--text' : 'subtext--text'">
                  <span :id="`hold${item.token}d_cpnl`">{{ item.d_cpnl ? item.d_cpnl : "0.00" }}</span
                  >%
                </p>
              </td>
              <td class="text-right font-weight-medium">
                <p class="font-weight-medium maintext--text mb-0">
                  <span v-if="item.exchs != 'MF'" :id="`hold${item.token}pnl`">{{ item.pnl ? item.pnl : "0.00" }}</span>
                  <span v-else>{{ item.pnl ? item.pnl : "0.00" }}</span>
                </p>
              </td>
              <td class="text-right font-weight-medium">
                <p class="font-weight-medium mb-0" :id="`hold${item.token}pnlcclr`" :class="item.pnlc > 0 ? 'maingreen--text' : item.pnlc < 0 ? 'mainred--text' : 'subtext--text'">
                  <span v-if="item.exchs != 'MF'" :id="`hold${item.token}pnlc`">{{ item.pnlc ? item.pnlc : "0.00" }}</span>
                  <span v-else>{{ item.pnlc ? item.pnlc : "0.00" }}</span
                  >%
                </p>
              </td>
              <!-- <td class="text-right font-weight-medium">
                <p class="font-weight-medium maintext--text mb-0"
                  :class="(item.npoadqty == 0 && item.pnl > 0) ? 'maingreen--text'  : (item.npoadqty == 0 && item.pnl < 0) ? 'mainred--text'  : 'subtext--text'}!important;`">
                  {{ item.npoadqty == 0 ? item.pnl ? `${item.pnl}` : '0.00' : '0.00' }}</p>
              </td>
              <td class="text-right font-weight-medium">
                <p class="font-weight-medium maintext--text mb-0"
                  :class="(item.npoadqty != 0 && item.pnl > 0) ? 'maingreen--text'  : (item.npoadqty != 0 && item.pnl < 0) ? 'mainred--text'  : 'subtext--text'}!important;`">
                  {{ item.npoadqty != 0 ? item.pnl ? `${item.pnl}` : '0.00' : '0.00' }}</p>
              </td> -->
            </tr>
          </tbody>
          <tbody v-else>
            <td :colspan="headers.length" class="text-center">
              <div class="mx-auto py-16 mt-16">
                <img class="mx-auto" width="80px" :src="require('@/assets/no data folder.svg')" />
                <h4 class="txt-999 font-weight-regular caption">There is no trading data here yet!</h4>
              </div>
            </td>
          </tbody>
        </template>
      </v-data-table>
    </div>
    <v-navigation-drawer hide-overlay color="cardbg" fixed temporary app class="pt-2" v-model="holdingdrawer" right width="360px">
      <template v-slot:prepend>
        <v-toolbar class="nav-drawer crd-trn" dense>
          <v-icon @click="holdingdrawer = false" color="maintext" size="20">mdi-close</v-icon>
          <p class="maintext--text font-weight-bold mb-0 ml-2">Holding Details</p>
          <v-spacer></v-spacer>
          <!-- <v-icon color="#666" size="20">mdi-headphones</v-icon>
          <p class="txt-666 font-weight-medium mb-0 ml-2">Need Help ?</p> -->
        </v-toolbar>
      </template>

      <v-list-item class="py-3">
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium maintext--text mb-3"
            >{{ singledata.tsym ? singledata.tsym : "" }} <span class="ml-1 subtext--text fs-10">{{ singledata && singledata.exchs ? singledata.exchs : singledata.exch ? singledata.exch : "" }}</span></v-list-item-title
          >
          <v-list-item-title class="maintext--text font-weight-medium fs-16 mb-1">{{ singledata.ltp ? singledata.ltp : "0.00" }}</v-list-item-title>
          <v-list-item-title :class="singledata.cpnl > 0 ? 'maingreen--text' : singledata.cpnl < 0 ? 'mainred--text' : 'subtext--text'" class="font-weight-medium fs-12">{{ singledata.pnl ? singledata.pnl : "0.00" }} ({{ singledata.pnlc ? singledata.pnlc : "0.00" }}%)</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <div class="px-4">
        <div class="pb-6" v-if="singledata.exch == 'NSE' || singledata.exch == 'BSE' || singledata.exch == 'MCX'">
          <v-row>
            <v-col cols="6">
              <v-btn v-if="edisbtn" class="elevation-0 rounded-pill font-weight-bold text-none" color="primary" block>E-DIS</v-btn>

              <v-btn v-else target="_blank" :href="`https://profile.mynt.in/deposltory/?uid=${uid}&token=${stoken}`" class="elevation-0 rounded-pill font-weight-bold text-none" color="primary" block height="40px">DO POA</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn @click="setSSDtab('exit-order', singledata.token, singledata.exch, singledata.tsym, singledata.netqty < 0 ? 'b' : 's', singledata)" class="rounded-pill text-none font-weight-bold" block height="40px" outlined><v-icon size="20">mdi-close</v-icon> Exit</v-btn>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
        <v-list-item-title class="maintext--text font-weight-bold fs-14 py-4"
          >Quantity
          <p class="float-right mb-0">
            <v-chip
              small
              :color="singledata.netqty > 0 ? 'secgreen' : singledata.netqty < 0 ? 'secred' : 'secbg'"
              :text-color="singledata.netqty > 0 ? 'maingreen' : singledata.netqty < 0 ? 'mainred' : 'subtext'"
              :style="`border: 1px solid ${singledata.netqty > 0 ? '#C1E7BA' : singledata.netqty < 0 ? '#FFCDCD' : '#DDD'}; border-radius: 5px; padding: 10px 8px !important;`"
            >
              <span class="font-weight-medium fs-12">{{ singledata.netqty > 0 ? `+${singledata.netqty}` : singledata.netqty < 0 ? `${singledata.netqty}` : "0" }}</span>
            </v-chip>
          </p>
        </v-list-item-title>
        <v-divider></v-divider>
        <v-list-item-title class="maintext--text font-weight-bold fs-14 py-4"
          >Pledged Qty
          <p class="float-right mb-0">
            <v-chip v-if="singledata.plgqty > 0" small class="table-hov-prd" text-color="#666" style="border-radius: 5px; padding: 10px 8px !important">
              <v-icon color="#999" size="12">mdi-lock</v-icon>
              <span class="font-weight-medium fs-12">{{ singledata.plgqty }}</span>
            </v-chip>
            <span v-else>0</span>
          </p>
        </v-list-item-title>
        <v-divider></v-divider>
        <v-list-item-title class="maintext--text font-weight-bold fs-14 py-4"
          >Avg price
          <p class="float-right mb-0">{{ singledata.upldprc ? singledata.upldprc.toLocaleString() : "0.00" }}</p>
        </v-list-item-title>
        <v-divider></v-divider>
        <v-list-item-title class="maintext--text font-weight-bold fs-14 py-4"
          >Last trade price
          <p class="float-right mb-0">
            {{ singledata.ltp ? singledata.ltp : "0.00" }}
          </p>
        </v-list-item-title>
        <v-divider></v-divider>
        <v-list-item-title class="maintext--text font-weight-bold fs-14 py-4"
          >Overall P&L
          <p class="float-right mb-0">
            {{ singledata.pnl ? singledata.pnl : "0.00" }} <span class="fs-12" :class="singledata.pnlc > 0 ? 'maingreen--text' : singledata.pnlc < 0 ? 'mainred--text' : 'subtext--text'">({{ singledata.pnlc ? singledata.pnlc : "0.00" }}%)</span>
          </p>
        </v-list-item-title>
        <v-divider></v-divider>
        <v-list-item-title class="maintext--text font-weight-bold fs-14 py-4"
          >Day P&L
          <p class="float-right mb-0">
            {{ singledata.d_pnl ? singledata.d_pnl : "0.00" }} <span class="fs-12" :class="singledata.d_cpnl > 0 ? 'maingreen--text' : singledata.d_cpnl < 0 ? 'mainred--text' : 'subtext--text'">({{ singledata.d_cpnl ? singledata.d_cpnl : "0.00" }}%)</span>
          </p>
        </v-list-item-title>
        <v-divider></v-divider>
        <v-list-item-title class="maintext--text font-weight-bold fs-14 py-4"
          >Investment
          <p class="float-right mb-0">{{ singledata.inv ? singledata.inv : "" }}</p>
        </v-list-item-title>
        <v-divider></v-divider>
        <v-list-item-title class="maintext--text font-weight-bold fs-14 py-4"
          >Current value
          <p class="float-right mb-0">{{ singledata.curr ? singledata.curr : "" }}</p>
        </v-list-item-title>
        <v-divider></v-divider>
        <v-list-item-title class="maintext--text font-weight-bold fs-14 py-4"
          >Product
          <p class="float-right mb-0">{{ singledata.prd ? (singledata.prd == "I" ? "INTRADAY" : singledata.prd == "C" ? "DELIVERY" : "MARKET") : "" }}</p>
        </v-list-item-title>
        <v-divider></v-divider>
        <v-list-item-title class="maintext--text font-weight-bold fs-14 py-4"
          >Order type
          <p class="float-right mb-0">{{ singledata.s_prdt_ali ? singledata.s_prdt_ali : "-" }}</p>
        </v-list-item-title>
        <v-divider></v-divider>
        <v-list-item-title class="maintext--text font-weight-bold fs-14 py-4"
          >ISIN
          <p class="float-right mb-0">{{ singledata.ISIN ? singledata.ISIN : "-" }}</p>
        </v-list-item-title>
      </div>
      <template v-slot:append v-if="singledata.exch == 'NSE' || singledata.exch == 'BSE' || singledata.exch == 'MCX'">
        <v-divider></v-divider>
        <div class="pa-4">
          <v-btn
            @click="switchBus.$emit('menudialog', 'order', singledata.token, singledata.exch, singledata.tsym, singledata.netqty > 0 ? 'b' : 's')"
            class="rounded-pill text-none font-weight-bold elevation-0"
            :color="singledata.netqty > 0 ? 'secgreen' : singledata.netqty < 0 ? 'secred' : 'secbg'"
            :text-color="singledata.netqty > 0 ? 'maingreen' : singledata.netqty < 0 ? 'mainred' : 'subtext'"
            block
            height="40px"
            :style="`border: 2px solid ${singledata.netqty > 0 ? '#C1E7BA' : singledata.netqty < 0 ? '#FFCDCD' : '#DDD'};`"
          >
            <v-icon size="20">mdi-plus</v-icon> Add</v-btn
          >
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
// import apiurl from '../../apiurl.js'
// import hold from "../../dataD.json";
// import holdmf from "../../dataDMF.json";
import {getQuotesdata, getMFQuotesdata, getMHoldings, getMMHoldings, getHsTokenapi, getMFholdings} from "../../components/mixins/getAPIdata.js";
import CryptoJS from "crypto-js";
import eventBus from "../../eventBus.js";

export default {
  data: () => ({
    uid: null,
    mtoken: null,
    stoken: null,
    holdingdrawer: false,
    singledata: [],
    loading: false,
    dashitems: [
      {val: "all", txt: "All"},
      {val: "stocks", txt: "Stocks"},
      {val: "fno", txt: "F&O"},
      {val: "comm", txt: "Commodities"},
      {val: "curr", txt: "Currency"},
      {val: "bond", txt: "Bonds"},
      // { val: 'mf', txt: 'Mutual Funds' },
      // { val: 'ipo', txt: 'Ipos' },
    ],
    exchtype: "all",
    menulist: [
      {name: "Add", icon: "mdi-plus", type: "order"},
      {name: "Exit", icon: "mdi-close", type: "exit-order", hr: true},
      {name: "Create GTT / GTC", icon: 4, type: "cGTT"},
      {name: "Create Alert", icon: 5, type: "alert"},
      {name: "Market Depth", icon: 6, type: "depth"},
      {name: "Chart", icon: 7, type: "chart", hr: true},
      // { name: 'Add to market watch', icon: 8, type: 'watch' },
      {name: "Fundamentals", icon: 9, type: "Funda"},
      {name: "Details", icon: 10, type: ""},
    ],
    opensearch: null,
    filter: null,
    filters: [],

    holdingtype: 0,

    holdingdata: [],

    statholding: [],

    edisbtn: false,

    holds: 0,
    mfholds: 0,
    holdsorted: [],

    switchBus: null,
  }),
  computed: {
    holdingheader() {
      return [
        {text: "Instrument", value: "tsym"},
        {text: "", value: "plgqty", class: "ws-p", sortable: false},
        {text: "", value: "holdqty", class: "ws-p", sortable: false},
        {text: "Net Qty", value: "netqty", class: "ws-p"},
        {text: "Avg Price", value: "upldprc", class: "ws-p", align: "right"},
        {text: "LTP", value: "ltp", class: "ws-p", align: "right"},

        {text: "Invested", value: "inv", class: "ws-p", align: "right"},
        {text: "Current Value", value: "curr", align: "right", class: "ws-p"},
        {text: "Day P&L", value: "d_pnl", align: "right", class: "ws-p"},
        {text: "Day %", value: "d_cpnl", align: "right", class: "ws-p"},
        {text: "Overall P&L", value: "pnl", align: "right", class: "ws-p"},
        {text: "Overall %", value: "cpnl", align: "right", class: "ws-p"},
        {text: "", value: "idx", class: " d-none"},
        {text: "", value: "exchs", filter: this.setExchpos, class: " d-none"},
      ];
    },
    holdingMHheader() {
      return [
        {text: "Instrument", value: "tsym", sortable: false},
        {text: "", value: "plgqty", sortable: false, class: "ws-p"},
        {text: "Qty", value: "netqty", sortable: false, class: "ws-p"},
        {text: "Avg Price", value: "avg", sortable: false, align: "right", class: "ws-p"},
        {text: "NAV", value: "ltp", sortable: false, align: "right"},
        {text: "Invested", value: "inv", sortable: false, align: "right"},
        {text: "Current Value", value: "curr", sortable: false, align: "right", class: "ws-p"},
        {text: "Overall P&L", value: "pnl", sortable: false, align: "right", class: "ws-p"},
        {text: "Overall %", value: "cpnl", sortable: false, align: "right", class: "ws-p"},
        {text: "", value: "idx", class: " d-none"},
        {text: "", value: "exchs", filter: this.setExchpos, class: " d-none"},
      ];
    },
  },
  created() {
    //
  },
  mounted() {
    let res = sessionStorage.getItem("c3RhdHVz");
    if (res == "dmFsaWR1c2Vy") {
      this.mtoken = sessionStorage.getItem("msession");
      this.uid = sessionStorage.getItem("userid");
      this.stoken = sessionStorage.getItem("usession");
      this.getHoldingbook();
    } else {
      this.$router.push("/");
    }
    eventBus.$on("web-scoketConn", (data, page) => {
      if (page == "holding") {
        this.optionChainDataParse(data);
      }
    });
    eventBus.$on("orderbook-update", (book) => {
      if (book == "port-order") {
        this.getHoldingbook();
      }
    });

    this.switchBus = eventBus;
  },
  beforeDestroy() {
    eventBus.$off("orderbook-update");
    // eventBus.$off('web-scoketConn');
  },
  methods: {
    customSort(items, index, isDesc) {
      if (index[0] && index[0] != "tsym") {
        // return items;
        this.holdsorted = [index[0], isDesc[0]];
        this.optionChainDataParse("", true);
        // this.holdingdata = [];
        // setTimeout(() => {
        //   this.holdingdata = items;
        // }, 1);
      } else {
        this.holdsorted = [];
        items.sort((a, b) => {
          if (isDesc != "false") {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
          }
        });
        this.setHfilter(true);
      }
      // items.sort((a, b) => {
      //   if (isDesc != "false") {
      //     return a[index] < b[index] ? -1 : 1;
      //   } else {
      //     return b[index] < a[index] ? -1 : 1;
      //   }
      // });
      return items;
    },
    setWebsocket(flow, data, is) {
      eventBus.$emit("web-scoketOn", flow, data, is, "holding");
    },
    async getHoldingbook() {
      this.loading = true;
      let data = await getMHoldings();
      let datamf = await getMMHoldings();
      // data = hold
      this.holdingdata = [];
      var sum = 0;
      if (data && data.length > 0) {
        for (let q = 0; q < data.length; q++) {
          data[q]["idx"] = q;
          data[q]["exch"] = data[q].exch_tsym[0].exch;
          data[q]["exchs"] = data[q].exch_tsym[0].tsym.includes("-TB") ? "T-BILL" : data[q].exch_tsym[0].tsym.includes("-GS") ? "G-SEC" : data[q].exch_tsym[0].tsym.includes("-GB") ? "SGB" : data[q].exch_tsym[0].exch;
          // data[q]['exch'] = data[q].exch_tsym[0].exch
          data[q]["token"] = data[q].exch_tsym[0].token;
          data[q]["tsym"] = data[q].exch_tsym[0].tsym;
          data[q]["pp"] = data[q].exch_tsym[0].pp;
          data[q]["ti"] = data[q].exch_tsym[0].ti;
          data[q]["ls"] = data[q].exch_tsym[0].ls;
          data[q]["isin"] = data[q].exch_tsym[0].isin;
          //           Valuation : btstqty + holdqty + brkcolqty + unplgdqty + benqty + Max(npoadqty, dpqty) - usedqty
          // Salable: btstqty + holdqty + unplgdqty + benqty + dpqty - usedqty
          data[q]["netqty"] =
            Number(data[q].btstqty) > 0
              ? Number(data[q].btstqty)
              : 0 + Number(data[q].holdqty) > 0
              ? Number(data[q].holdqty)
              : 0 + Number(data[q].brkcolqty) > 0
              ? Number(data[q].brkcolqty)
              : 0 + Number(data[q].unplgdqty) > 0
              ? Number(data[q].unplgdqty)
              : 0 + Number(data[q].benqty) > 0
              ? Number(data[q].benqty)
              : 0 + Math.max(Number(data[q].npoadt1qty) > 0 ? Number(data[q].npoadt1qty) : 0, Number(data[q].npoadqty) > 0 ? Number(data[q].npoadqty) : 0, Number(data[q].dpqty) > 0 ? Number(data[q].dpqty) : 0) - (Number(data[q].usedqty) > 0 ? Number(data[q].usedqty) : 0);
          data[q]["salqty"] = data[q].btstqty > 0 ? data[q].btstqty : 0 + data[q].holdqty > 0 ? data[q].holdqty : 0 + data[q].unplgdqty > 0 ? data[q].unplgdqty : 0 + data[q].benqty > 0 ? data[q].benqty : 0 + data[q].dpqty > 0 ? data[q].dpqty : 0 - data[q].usedqty > 0 ? data[q].usedqty : 0;
          data[q]["plgqty"] = data[q].brkcolqty > 0 ? data[q].brkcolqty : 0;
          data[q]["action"] = data[q].exch == "NSE" || data[q].exch == "BSE" || data[q].exch == "NFO" || data[q].exch == "MCX" || data[q].exch == "CD" ? true : false;
          this.holdingdata.push(data[q]);

          if (data[q].npoadqty && Number(data[q].npoadqty > 0)) {
            sum += Number(data[q].npoadqty);
          }
        }
        this.holds = data.length;

        if (sum > 0) {
          this.edisbtn = true;
        } else {
          this.edisbtn = false;
        }
        this.setWebsocket("sub", this.holdingdata, "hold");
        // axiosThis.setExch();
      } else {
        this.holdingdata = [];
        eventBus.$emit("snack-event", 2, data && data.emsg ? data.emsg : data);
      }
      // datamf = holdmf
      if (datamf && datamf.length > 0) {
        for (let q = 0; q < datamf.length; q++) {
          datamf[q]["idx"] = this.holdingdata.length + q + 1;
          datamf[q]["exch"] = datamf[q].exch_tsym[0].exch;
          datamf[q]["exchs"] = "MF";

          datamf[q]["token"] = datamf[q].exch_tsym[0].token;
          datamf[q]["tsym"] = datamf[q].exch_tsym[0].tsym;
          datamf[q]["name"] = datamf[q].exch_tsym[0].cname;
          datamf[q]["pp"] = datamf[q].exch_tsym[0].pp;
          datamf[q]["ti"] = datamf[q].exch_tsym[0].ti;
          datamf[q]["isin"] = datamf[q].exch_tsym[0].isin;

          datamf[q]["upldprc"] = datamf[q].upload_prc;

          datamf[q]["tolqty"] = Math.round(datamf[q].npoadqty > 0 ? datamf[q].npoadqty : datamf[q].holdqty > 0 ? datamf[q].holdqty : datamf[q].btstqty > 0 ? datamf[q].btstqty : 0);
          // datamf[q]['salqty'] = datamf[q].tolqty > 0 ? datamf[q].tolqty - datamf[q].colqty : 0
          datamf[q]["plgqty"] = Math.round(datamf[q].colqty > 0 ? datamf[q].colqty : 0);
          datamf[q]["netqty"] = Math.round(datamf[q].npoadqty > 0 ? datamf[q].npoadqty : datamf[q].holdqty > 0 ? datamf[q].holdqty : datamf[q].colqty > 0 ? datamf[q].colqty : datamf[q].btstqty > 0 ? datamf[q].btstqty : 0);
          datamf[q]["quotes"] = await getMFQuotesdata(`${datamf[q].exch}|${datamf[q].token}`);
          datamf[q]["action"] = false;
          // datamf[q]['ltp'] = datamf[q].quotes && datamf[q].quotes.nav ?  datamf[q].quotes.nav : 0
          this.holdingdata.push(datamf[q]);
        }
        this.mfholds = datamf.length;
        this.setWebsocket("sub", datamf, "hold");
      }
      this.loading = false;

      if (this.mfholds > 0) {
        let mfhold = await getMFholdings();
        if (mfhold && mfhold.data && mfhold.data.length > 0) {
          for (let q = 0; q < this.holdingdata.length; q++) {
            if (this.holdingdata[q].exchs === "MF") {
              var s = mfhold.data.findIndex((o) => o.ISIN == this.holdingdata[q].isin);
              this.holdingdata[q]["red"] = mfhold.data[s];
            }
          }
        }
      }
    },

    setExchpos(value) {
      if (this.exchtype == "all") {
        if (!this.exchtype && value != "MF") {
          return true;
        }
        return value != "MF";
      }
      if (this.exchtype == "stocks") {
        if (!this.exchtype && (value == "NSE" || value == "BSE")) {
          return true;
        }
        return value === "NSE" || value === "BSE";
      } else if (this.exchtype == "fno") {
        if (!this.exchtype && value == "NFO") {
          return true;
        }
        return value === "NFO";
      } else if (this.exchtype == "comm") {
        if (!this.exchtype && value == "MCX") {
          return true;
        }
        return value === "MCX";
      } else if (this.exchtype == "curr") {
        if (!this.exchtype && value == "CD") {
          return true;
        }
        return value === "CD";
      } else if (this.exchtype == "bond") {
        if (!this.exchtype && (value == "T-BILL" || value == "G-SEC" || value == "SGB")) {
          return true;
        }
        return value == "T-BILL" || value == "G-SEC" || value == "SGB";
      } else if (this.exchtype == "ipo") {
        if (!this.exchtype && value == "IPO") {
          return true;
        }
        return value === "IPO";
      } else if (this.exchtype == "mf") {
        if (!this.exchtype && value == "MF") {
          return true;
        }
        return value === "MF";
      } else {
        if (!this.exchtype) {
          return true;
        }
        return value === this.exchtype;
      }
    },

    setSSDtab(type, token, exch, tsym, trans, item) {
      if (type == "alert") {
        eventBus.$emit("menudialog", "alert", token, exch, tsym);
      } else if (type == "cGTT") {
        this.holdingdrawer = false;
        eventBus.$emit("menudialog", "order-GTT", token, exch, tsym, "b");
      } else if (type == "exit-order") {
        eventBus.$emit("menudialog", type, token, exch, tsym, trans, item);
      } else if (type == "order") {
        eventBus.$emit("menudialog", type, token, exch, tsym, item.netqty < 0 ? "s" : "b", item);
      } else {
        let path = [type, token, exch, tsym];
        this.$router.push({name: "stocks details", params: {val: path}});
      }
    },
    optionChainDataParse(data, sort) {
      if (data) {
        let w = this.holdingdata.findIndex((o) => o.token == data.token);
        if (w >= 0 && this.holdingdata[w].token == data.token) {
          if (this.holdingdata[w].exchs == "MF") {
            data["lp"] = this.holdingdata[w].quotes && this.holdingdata[w].quotes.nav ? this.holdingdata[w].quotes.nav : 0;
          }
          this.holdingdata[w].ltp = this.holdingdata[w].exchs == "MF" ? Number(data.lp) : Number(data.lp).toFixed(2);
          this.holdingdata[w]["inv"] = ((Number(this.holdingdata[w].upldprc) != 0 ? Number(this.holdingdata[w].upldprc) : this.holdingdata[w].ltp) * Math.abs(this.holdingdata[w].netqty)).toFixed(2);
          this.holdingdata[w]["curr"] = (Number(data.lp) * Math.abs(this.holdingdata[w].netqty)).toFixed(2);
          // this.holdingdata[w]['pre'] = (Number(data.lp) * Math.abs(this.holdingdata[w].netqty)).toFixed(2);
          this.holdingdata[w]["pnl"] = (this.holdingdata[w]["curr"] - this.holdingdata[w]["inv"]).toFixed(2);
          this.holdingdata[w]["pnlc"] = this.holdingdata[w]["inv"] > 0 ? ((this.holdingdata[w]["pnl"] / this.holdingdata[w]["inv"]) * 100).toFixed(2) : "0";
          this.holdingdata[w]["hp"] = (Number(data.high_price) - Number(data.prev_close_price)) * Math.abs(this.holdingdata[w].netqty);
          this.holdingdata[w]["lp"] = (Number(data.prev_close_price) - Number(data.low_price)) * Math.abs(this.holdingdata[w].netqty);
          this.holdingdata[w]["d_pnl"] = ((Number(data.lp) - Number(data.prev_close_price)) * Math.abs(this.holdingdata[w].netqty)).toFixed(2);
          this.holdingdata[w]["d_cpnl"] = ((this.holdingdata[w].d_pnl / this.holdingdata[w].inv) * 100).toFixed(2);
          let tag = document.getElementById(`hold${data.token}ltp`);
          if (tag) {
            document.getElementById(`hold${data.token}ltp`).innerHTML = this.holdingdata[w].ltp;
            document.getElementById(`hold${data.token}inv`).innerHTML = this.holdingdata[w].inv;
            document.getElementById(`hold${data.token}curr`).innerHTML = this.holdingdata[w].curr;
            document.getElementById(`hold${data.token}pnl`).innerHTML = this.holdingdata[w].pnl;
            document.getElementById(`hold${data.token}pnlc`).innerHTML = this.holdingdata[w].pnlc;
            eventBus.$emit("color-event", `hold${data.token}pnlcclr`, this.holdingdata[w].pnlc > 0 ? "maingreen--text" : this.holdingdata[w].pnlc < 0 ? "mainred--text" : "subtext--text");
          }
          let tago = document.getElementById(`hold${data.token}d_pnl`);
          if (tago) {
            document.getElementById(`hold${data.token}d_pnl`).innerHTML = this.holdingdata[w].d_pnl;
            document.getElementById(`hold${data.token}d_cpnl`).innerHTML = this.holdingdata[w].d_cpnl;
            eventBus.$emit("color-event", `hold${data.token}d_cpnlclr`, this.holdingdata[w].d_cpnl > 0 ? "maingreen--text" : this.holdingdata[w].d_cpnl < 0 ? "mainred--text" : "subtext--text");
          }
          // this.$set(this.holdingdata, w, this.holdingdata[w]);
        }
      }
      if (this.holdsorted && this.holdsorted[0]) {
        this.setHfilter(sort, true);
      }
      let statdata = this.holdingdata.filter((x) => (this.holdingtype == 0 ? x.exchs != "MF" : x.exchs == "MF"));
      if (statdata) {
        this.statholding["positive"] = statdata.filter((x) => x.pnlc > 0);
        this.statholding["negative"] = statdata.filter((x) => x.pnlc < 0);

        let stockvalue = statdata.reduce((acc, o) => acc + (Number(o.curr) ? Number(o.curr) : 0), 0);
        this.statholding["stockvalue"] = stockvalue > 0 || stockvalue < 0 ? this.differentView(Math.abs(stockvalue)) : 0;
        let invested = statdata.reduce((acc, o) => acc + (Number(o.inv) ? Number(o.inv) : 0), 0);
        this.statholding["invested"] = invested > 0 || invested < 0 ? this.differentView(Math.abs(invested)) : 0;

        let d_pnl = statdata.reduce((acc, o) => acc + (Number(o.d_pnl) ? Number(o.d_pnl) : 0), 0);
        this.statholding["d_pnl"] = d_pnl > 0 || d_pnl < 0 ? Math.abs(d_pnl).toFixed(2) : 0;
        this.statholding["d_cpnl"] = ((statdata.reduce((acc, o) => acc + Number(o.d_pnl), 0) / statdata.reduce((acc, o) => acc + (Number(o.inv) ? Number(o.inv) : 0), 0)) * 100).toFixed(2);

        let pnl = statdata.reduce((acc, o) => acc + (Number(o.pnl) ? Number(o.pnl) : 0), 0).toFixed(2);
        this.statholding["pnl"] = pnl > 0 || pnl < 0 ? this.differentView(Math.abs(pnl)) : 0;
        this.statholding["cpnl"] = pnl > 0 || pnl < 0 ? ((statdata.reduce((acc, o) => acc + (Number(o.pnl) ? Number(o.pnl) : 0), 0) / statdata.reduce((acc, o) => acc + (Number(o.inv) ? Number(o.inv) : 0), 0)) * 100).toFixed(2) : 0;

        this.statholding["hp"] = this.differentView(statdata.reduce((acc, o) => acc + (Number(o.hp) ? Number(o.hp) : 0), 0).toFixed(2));
        this.statholding["lp"] = this.differentView(statdata.reduce((acc, o) => acc + (Number(o.lp) ? Number(o.lp) : 0), 0).toFixed(2));

        this.statholding["chp"] = ((statdata.reduce((acc, o) => acc + (Number(o.d_pnl) ? Number(o.d_pnl) : 0), 0) / statdata.reduce((acc, o) => acc + (Number(o.hp) ? Number(o.hp) : 0), 0)) * 100).toFixed(2);
        this.statholding["clp"] = ((statdata.reduce((acc, o) => acc + (Number(o.d_pnl) ? Number(o.d_pnl) : 0), 0) / statdata.reduce((acc, o) => acc + (Number(o.lp) ? Number(o.lp) : 0), 0)) * 100).toFixed(2);

        let tag = document.getElementById(`holdstatval`);
        if (tag) {
          tag.innerHTML = this.differentView(stockvalue);

          document.getElementById(`holdstatP`).innerHTML = this.statholding.positive.length;
          document.getElementById(`holdstatN`).innerHTML = this.statholding.negative.length;
          document.getElementById(`holdstatinv`).innerHTML = this.statholding.invested;

          document.getElementById(`holdstatpnl`).innerHTML = this.statholding.pnl;
          document.getElementById(`holdstatpnlc`).innerHTML = this.statholding.cpnl;
          eventBus.$emit("color-event", `holdstatpnlcclr`, this.statholding.cpnl > 0 ? "maingreen--text" : this.statholding.cpnl < 0 ? "mainred--text" : "subtext--text");
        }
        let tago = document.getElementById(`holdstatdpnl`);
        if (tago) {
          document.getElementById(`holdstatdpnl`).innerHTML = this.statholding.d_pnl;
          document.getElementById(`holdstatdpnlc`).innerHTML = this.statholding.d_cpnl;
          eventBus.$emit("color-event", `holdstatdpnlcclr`, this.statholding.d_cpnl > 0 ? "maingreen--text" : this.statholding.d_cpnl < 0 ? "mainred--text" : "subtext--text");
        }
      }
    },
    setHfilter(sort, main) {
      if (main) {
        this.holdingdata.sort((a, b) => {
          if (this.holdsorted[1] != false) {
            return Number(a[this.holdsorted[0]]) < Number(b[this.holdsorted[0]]) ? -1 : 1;
          } else {
            return Number(b[this.holdsorted[0]]) < Number(a[this.holdsorted[0]]) ? -1 : 1;
          }
        });
      }
      if (sort) {
        setTimeout(() => {
          for (let q = 0; q < this.holdingdata.length; q++) {
            var a = this.holdingdata[q];
            let tag = document.getElementById(`hold${a.token}ltp`);
            if (tag) {
              document.getElementById(`hold${a.token}ltp`).innerHTML = a.ltp;
              document.getElementById(`hold${a.token}inv`).innerHTML = a.inv;
              document.getElementById(`hold${a.token}curr`).innerHTML = a.curr;
              document.getElementById(`hold${a.token}pnl`).innerHTML = a.pnl;
              document.getElementById(`hold${a.token}pnlc`).innerHTML = a.pnlc;
              eventBus.$emit("color-event", `hold${a.token}pnlcclr`, a.pnlc > 0 ? "maingreen--text" : a.pnlc < 0 ? "mainred--text" : "subtext--text");
            }
            let tago = document.getElementById(`hold${a.token}d_pnl`);
            if (tago) {
              document.getElementById(`hold${a.token}d_pnl`).innerHTML = a.d_pnl;
              document.getElementById(`hold${a.token}d_cpnl`).innerHTML = a.d_cpnl;
              eventBus.$emit("color-event", `hold${a.token}d_cpnlclr`, a.d_cpnl > 0 ? "maingreen--text" : a.d_cpnl < 0 ? "mainred--text" : "subtext--text");
            }
          }
        }, 1);
      }
    },

    differentView(view) {
      return view && Number(view) && view > 0 ? Number(view).toFixed(2) : "0.00";
    },
    async setHoldingrowdata(item) {
      this.singledata = {};
      this.holdingdrawer = true;
      this.singledata = item;
      this.singledata["quotes"] = await getQuotesdata(`${item.exch}|${item.token}`);
    },
    async setdoEdis() {
      let data = await getHsTokenapi();
      if (data && data.stat == "Ok") {
        let beforehas = CryptoJS.enc.Utf8.parse(`sLoginId=${this.uid}&sAccountId=${this.uid}&prd=C&token=${data.hstk}&sBrokerId=ZEBU&open=edis`);
        let codeforedis = CryptoJS.enc.Base64.stringify(beforehas);
        window.open(`https://go.mynt.in/NorenEdis/NonPoaHoldings/?${codeforedis}`, "_blank");
      } else {
        eventBus.$emit("snack-event", 2, data && data.emsg ? data.emsg : data);
      }
    },
  },
};
</script>
