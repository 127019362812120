<template>
  <div>
    <v-toolbar flat dense class="crd-trn tool-sty pt-3 mb-6 mt-4">
      <div>
        <p class="font-weight-bold fs-22 mb-0">Securities Funds</p>
        <p class="subtitle-2 subtext--text">View margin breakup & add/withdraw fund.</p>
      </div>

      <v-spacer></v-spacer>

      <v-icon class="ml-3 cursor-p" @click="getAllmargin()" color="maintext" size="24">mdi-reload</v-icon>
    </v-toolbar>

    <v-card :loading="loader" class="px-6 mb-6 rounded-lg" style="border: 1px solid var(--outline) !important" outlined width="100%">
      <v-row class="py-6">
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12" class="pb-2">
              <p class="fs-14 subtext--text font-weight-medium mb-0 lh-16">Total credits</p>
              <v-text-field :value="`₹${alllimit.total ? alllimit.total : '0.00'}`" class="mt-0 font-weight-bold" readonly flat color="#999999"> </v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="py-0 pb-2">
              <p class="fs-14 subtext--text font-weight-regular mb-0 lh-16">Opening balance</p>
              <v-text-field :value="`₹${alllimit.cash ? alllimit.cash : '0.00'}`" class="mt-0 font-weight-bold" readonly flat color="#999999"> </v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0 pb-2">
              <p class="fs-14 subtext--text font-weight-regular mb-0 lh-16">Broker collateral</p>
              <v-text-field :value="`₹${alllimit.brkcollamt ? alllimit.brkcollamt : '0.00'}`" class="mt-0 font-weight-bold" readonly flat color="#999999"> </v-text-field>
            </v-col>
            <v-col cols="12" class="py-0 pb-2">
              <p class="fs-12 txt-999 mb-6 mt-2">Note: Only 80% of the amount after selling Demat holdings will be available to trade on the same day. The remaining 20% will be available to trade on the next day. Learn more.</p>
              <p class="fs-14 subtext--text font-weight-medium lh-16 mb-0">
                Margin used
                <v-btn icon @click="limtexpan = !limtexpan"
                  ><v-icon color="#999">{{ alllimit && limtexpan ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon></v-btn
                >
              </p>
              <v-text-field :value="`₹${alllimit.marginused ? alllimit.marginused : '0.00'}`" class="mt-0 font-weight-bold" readonly flat color="#999999"> </v-text-field>
              <div v-if="limtexpan" class="mb-4">
                <p class="fs-14 subtext--text font-weight-regular mb-2 lh-24">
                  Span
                  <span class="font-weight-bold maintext--text float-right">{{ alllimit.span ? alllimit.span : "0.00" }}</span>
                </p>
                <p class="fs-14 subtext--text font-weight-regular mb-2 lh-24">
                  Exposure
                  <span class="font-weight-bold maintext--text float-right">{{ alllimit.expo ? alllimit.expo : "0.00" }}</span>
                </p>
                <p class="fs-14 subtext--text font-weight-regular mb-2 lh-24">
                  Additional margin
                  <span class="font-weight-bold maintext--text float-right">{{ alllimit.addmrg ? alllimit.addmrg : "0.00" }}</span>
                </p>
                <p class="fs-14 subtext--text font-weight-regular mb-2 lh-24">
                  Option premium
                  <span class="font-weight-bold maintext--text float-right">{{ alllimit.premium ? alllimit.premium : "0.00" }}</span>
                </p>
                <p class="fs-14 subtext--text font-weight-regular mb-2 lh-24">
                  Unrealized expenses
                  <span class="font-weight-bold maintext--text float-right">{{ alllimit.brokerage ? alllimit.brokerage : "0.00" }}</span>
                </p>
              </div>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <p class="fs-14 subtext--text font-weight-regular mb-0 lh-16">Peak margin</p>
              <v-text-field :value="`₹${alllimit.peak_mar ? alllimit.peak_mar : '0.00'}`" class="mt-0 font-weight-bold" readonly flat color="#999999"> </v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <p class="fs-14 subtext--text font-weight-regular mb-0 lh-16">Expiry margin</p>
              <v-text-field :value="`₹${alllimit.expiry_mar ? alllimit.expiry_mar : '0.00'}`" class="mt-0 font-weight-bold" readonly flat color="#999999"> </v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <p class="fs-14 subtext--text font-weight-medium mb-0 lh-16">Available balance to trade</p>
          <v-text-field :value="`₹${alllimit.avbma ? alllimit.avbma : '0.00'}`" class="mt-0 font-weight-bold" readonly flat color="#999999"> </v-text-field>

          <v-card class="elevation-0 pa-md-6 pa-4 rounded-lg" color="secbg">
            <p class="fs-14 subtext--text lh-16 mb-2">Choose type <v-icon @click="Submit(true)" class="float-right" size="16">mdi-arrange-send-backward</v-icon></p>
            <v-card outlined elevation="0" class="mb-6 rounded-lg">
              <v-row no-gutters>
                <v-col cols="6" align="center" class="overflow-hidden">
                  <v-btn :disabled="loader" @click="(pageis = 'fund'), (amountin = null)" :color="pageis == 'fund' ? '#000' : '#fff'" :class="pageis == 'fund' ? 'white--text' : 'black--text'" class="elevation-0 rounded-lg rounded-r-0" block> Add fund </v-btn>
                </v-col>
                <v-col cols="6" align="center" class="overflow-hidden">
                  <v-btn :disabled="loader" @click="(pageis = 'withdrawal'), (amountout = null)" :color="pageis == 'withdrawal' ? '#000' : '#fff'" :class="pageis == 'withdrawal' ? 'white--text' : 'black--text'" class="elevation-0 rounded-lg rounded-l-0" block> Withdrawal </v-btn>
                </v-col>
              </v-row>
            </v-card>

            <p class="fs-14 subtext--text lh-16 mb-2">{{ pageis == "fund" ? "Deposit" : "Withdraw" }} amount</p>
            <v-text-field v-on:keyup.enter="Submit()" :disabled="loader" v-if="pageis == 'fund'" prefix="₹" hide-details type="number" hide-spin-buttons v-model="amountin" solo flat background-color="mainbg" class="rounded-pill" placeholder="0" min="50" dense> </v-text-field>
            <v-text-field v-on:keyup.enter="Submit()" :disabled="loader" v-else prefix="₹" type="number" hide-spin-buttons v-model="amountout" solo flat background-color="mainbg" class="rounded-pill mb-0" placeholder="0" dense> </v-text-field>

            <p v-if="pageis == 'fund'" style="height: 20px" class="caption font-weight-medium ml-1 mt-1 mb-0 lh-18 error--text">
              <span>{{ amountin > 5000000 ? "Max amount ₹5,000,000" : amountin >= 50 ? "" : amountin >= 1 ? "Min amount ₹50" : "" }}</span>
            </p>
            <p class="fs-14 subtext--text lh-16 mb-2">Bank account</p>
            <div v-if="pageis == 'fund'" class="mb-6">
              <v-menu transition="slide-y-transition" bottom rounded="lg">
                <template v-slot:activator="{on, attrs}">
                  <div v-bind="attrs" v-on="on" :style="`cursor:${!acc_no || loader ? 'not-allowed' : ''};`">
                    <v-card v-if="acc_no" :ripple="false" height="48px" color="mainbg" class="px-3 elevation-0 rounded-pill pt-2">
                      <v-row class="align-center">
                        <v-col cols="2" class="pt-3">
                          <img width="34px" v-if="acc_no.logo" :src="acc_no.logo" class="pa-1" alt="bank" />
                          <img width="38px" v-else src="@/assets/bank.svg" alt="bank" />
                        </v-col>
                        <v-col cols="8" class="pt-2">
                          <p class="font-weight-bold mb-0 fs-14 lh-18">
                            {{ acc_no.bank_name ? acc_no.bank_name : "" }}
                          </p>
                          <p class="fs-10 mb-0 lh-18">
                            &#8226;&#8226;&#8226;&#8226; &#8226;&#8226;&#8226;&#8226;<span class="font-weight-bold"> {{ acc_no.bank_acc ? acc_no.bank_acc : "" }}</span>
                          </p>
                        </v-col>
                        <v-col cols="2" class="pt-2 text-md-right">
                          <v-icon size="32">mdi-chevron-down</v-icon>
                        </v-col>
                      </v-row>
                    </v-card>

                    <v-list-item v-else class="mx-4 pb-1">
                      <v-list-item-avatar>
                        <v-skeleton-loader type="card-avatar"></v-skeleton-loader>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <div>
                          <v-skeleton-loader type="text" class="grey lighten-5" animation="fade" max-width="160"></v-skeleton-loader>
                        </div>
                        <div>
                          <v-skeleton-loader type="text" class="grey lighten-5" animation="fade" max-width="120"></v-skeleton-loader>
                        </div>
                      </v-list-item-content>
                      <v-icon size="32">mdi-chevron-down</v-icon>
                    </v-list-item>
                  </div>
                </template>
                <v-list v-if="acc_no && !loader">
                  <v-list-item-group v-model="acc_no.idx" color="primary">
                    <v-list-item @change="acc_no = item" v-for="(item, i) in dict_data" :key="i" :disabled="loader">
                      <v-list-item-icon class="mr-3">
                        <img width="34px" v-if="item.logo" :src="item.logo" class="pa-1" alt="bank" />
                        <img width="38px" v-else src="@/assets/bank.svg" alt="bank" />
                      </v-list-item-icon>
                      <v-list-item-content>
                        <p class="font-weight-bold subtitle-2 mb-0 lh-18">
                          {{ item.bank_name ? item.bank_name : "" }}
                        </p>
                        <p class="fs-10 mb-0 lh-18">
                          &#8226;&#8226;&#8226;&#8226; &#8226;&#8226;&#8226;&#8226;<span class="font-weight-bold"> {{ item.bank_acc ? item.bank_acc : "" }}</span>
                        </p>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </div>

            <div v-if="pageis == 'fund'">
              <p class="fs-14 subtext--text lh-16 mb-2">Payment method</p>
              <v-radio-group v-model="paymet" row :disabled="loader">
                <v-radio label="UPI ID" value="upi" color="primary"></v-radio>
                <v-radio label="Scan QR" value="qr" color="primary"></v-radio>
                <v-radio label="Net banking" value="netbanking" color="primary"></v-radio>
              </v-radio-group>
            </div>

            <v-btn :disabled="((pageis == 'fund' && amountin > 49 && acc_no) || (pageis == 'withdrawal' && amountout > 0) ? false : true) || loader" @click="Submit()" target="_blank" color="btnclr" class="rounded-pill text-none btntext--text font-weight-bold elevation-0" block height="48px"
              >Proceed to {{ pageis == "fund" ? "Pay" : "Withdrawal" }}</v-btn
            >
          </v-card>
          <!-- {{`https://fund.mynt.in/${pageis}?uid=${uid}&token=${stoken}&payin=${amountin}&payout=${amountout}&paytype=${paymet}`}} -->
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import apiurl from "../../apiurl.js";
import eventBus from "../../eventBus.js";
import {getMLimits} from "../../components/mixins/getAPIdata.js";

export default {
  data: () => ({
    uid: null,
    stoken: null,
    mtoken: null,
    loader: true,

    alllimit: [],
    amountin: null,
    amountout: null,

    limtexpan: false,

    pageis: "fund",
    paymet: "upi",
    userdata: [],
    dict_data: [],
    acc_no: null,
  }),
  mounted() {
    this.$emit("receive", "stk");
    eventBus.$emit("login-event");

    eventBus.$on("profile-event", (type, data) => {
      if (type == "get") {
        var bank = data.bank_data;
        this.dict_data = [];
        for (let i = 0; i < bank.length; i++) {
          this.dict_data.push({
            idx: i,
            accno: bank[i].Bank_AcNo,
            bank_name: bank[i].Bank_Name,
            bank_acc: bank[i].Bank_AcNo.substr(-4, bank[i].Bank_AcNo.length),
            ifsc: bank[i].IFSC_Code,
            logo: `${apiurl.banklogo}/banklogo?bank=${bank[i].IFSC_Code.slice(0, 4).toLowerCase()}&type=svg`,
          });
        }
        this.acc_no = this.dict_data[0];
      }
    });

    eventBus.$on("user-event", () => {
      let res = sessionStorage.getItem("c3RhdHVz");
      if (res == "dmFsaWR1c2Vy") {
        this.mtoken = sessionStorage.getItem("msession");
        this.stoken = sessionStorage.getItem("usession");
        this.uid = sessionStorage.getItem("userid");
        this.getAllmargin();
        eventBus.$emit("profile-event", "set");
      } else {
        this.$router.push("/");
      }
    });
    eventBus.$on("orderbook-update", (book) => {
      if (book == "port-order") {
        this.getAllmargin();
      }
    });
  },
  beforeDestroy() {
    eventBus.$off("user-event");
  },

  methods: {
    Submit(flow) {
      var ass;
      if (flow) {
        ass = `https://fund.mynt.in?uid=${this.uid}&token=${this.stoken}`;
      } else if ((this.pageis == "fund" && this.amountin > 49) || (this.pageis == "withdrawal" && this.amountout > 0 && this.acc_no && this.acc_no.accno)) {
        var ba = btoa(
          JSON.stringify({
            payin: this.amountin,
            payout: this.amountout,
            paytype: this.paymet,
            accno: this.acc_no.accno,
          })
        );
        ass = `https://fund.mynt.in/${this.pageis}?uid=${this.uid}&token=${this.stoken}&payment=${ba}`; //   "_blank"
        this.pageis = "fund";
        this.amountin = null;
        this.amountout = null;
        this.paymet = "upi";
      }
      window.open(ass, "_blank");
    },
    async getAllmargin() {
      this.loader = true;
      let limit = await getMLimits();
      if (limit && limit.stat == "Ok") {
        this.alllimit = limit;
        this.alllimit["total"] = (Number(limit.brkcollamt) + Number(limit.cash)).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        var sum = ((Number(limit.brkcollamt) > 0 ? Number(limit.brkcollamt) : 0) + (Number(limit.cash) > 0 ? Number(limit.cash) : 0)) - Number(limit.marginused ? limit.marginused : 0);
        this.alllimit["avbma"] = (sum > 0 || sum < 0) ? (sum).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) : 0;

        // this.alllimit['cash'] = Number(limit.cash) != 0 ? Number(limit.cash).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null;
        // this.alllimit['brkcollamt'] = Number(limit.brkcollamt) != 0 ? Number(limit.brkcollamt).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null;
        // this.alllimit['marginused'] = Number(limit.marginused) != 0 ? Number(limit.marginused).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null;

        // this.alllimit['peak_mar'] = Number(limit.peak_mar) != 0 ? Number(limit.peak_mar).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null;
        // this.alllimit['expiry_mar'] = Number(limit.expiry_mar) != 0 ? Number(limit.expiry_mar).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null;

        // this.alllimit['span'] = Number(limit.span) != 0 ? Number(limit.span).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null;
        // this.alllimit['expo'] = Number(limit.expo) != 0 ? Number(limit.expo).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null;
        // this.alllimit['addmrg'] = Number(limit.addmrg) != 0 ? Number(limit.addmrg).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null;
        // this.alllimit['premium'] = Number(limit.premium) != 0 ? Number(limit.premium).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null;
        // this.alllimit['brokerage'] = Number(limit.brokerage) != 0 ? Number(limit.brokerage).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null;

        for (const [key, value] of Object.entries(this.alllimit)) {
          if (key && value && Number(value)) {
            this.alllimit[key] =
              Number(value) != 0
                ? Number(value).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : null;
          }
          // this.alllimit[key] = this.alllimit[value] == 'NaN' ? 0 : this.alllimit[value]
        }
        this.alllimit["mexp"] = false;
        // span, expo, add mar, opt pre, unr export, un mtm
      } else {
        this.alllimit = [];
        eventBus.$emit("snack-event", 2, limit.emsg ? limit.emsg : limit);
      }
      this.loader = false;
    },
  },
};
</script>
