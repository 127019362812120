<template>
  <div>
    <div class="mt-6" :class="ordertab == 2 ? 'mb-0' : 'mb-6'">
      <v-row>
        <v-col cols="12">
          <v-card class="px-2 stat-card rounded-md d-inline-flex pt-2 pb-1 overflow-hidden" width="100%">
            <v-list-item v-if="ordertab != 1">
              <v-list-item-content class="pt-0">
                <v-list-item-subtitle class="txt-5E6 mb-2 font-weight-medium">MTM</v-list-item-subtitle>
                <v-list-item-title class="maintext--text font-weight-medium" :class="statposition.mtm > 0 ? 'maingreen--text' : statposition.mtm < 0 ? 'mainred--text' : 'subtext--text'" id="poststatmtmclr"
                  ><span id="poststatmtm">{{ statposition.mtm ? statposition.mtm : "0.00" }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content class="pt-0">
                <v-list-item-subtitle class="txt-5E6 mb-2 font-weight-medium">Profit/Loss</v-list-item-subtitle>
                <v-list-item-title class="maintext--text font-weight-medium"
                  ><span id="poststatpnlclr" :class="statposition.cpnl > 0 ? 'maingreen--text' : statposition.cpnl < 0 ? 'mainred--text' : 'subtext--text'"
                    ><span id="poststatpnl">{{ statposition.pnl ? statposition.pnl : "0.00" }}</span></span
                  >&nbsp;
                  <span class="fs-13" id="poststatpnlclr" :class="statposition.cpnl > 0 ? 'maingreen--text' : statposition.cpnl < 0 ? 'mainred--text' : 'subtext--text'"
                    ><span id="poststatpnlc"> {{ statposition.cpnl > 0 || statposition.cpnl < 0 ? statposition.cpnl : "0.00" }}</span
                    >%
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="pt-0">
                <v-list-item-subtitle class="txt-5E6 mb-2 font-weight-medium">Trade Value</v-list-item-subtitle>
                <v-list-item-title class="maintext--text font-weight-medium"
                  ><span id="poststatval"> {{ statposition.tradeval ? statposition.tradeval : "0.00" }}</span></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="pt-0">
                <v-list-item-subtitle class="txt-5E6 mb-2 font-weight-medium">Open Position</v-list-item-subtitle>
                <v-list-item-title class="maintext--text font-weight-medium"
                  ><span id="poststatopnlclr" :class="statposition.opcpnl > 0 ? 'maingreen--text' : statposition.opcpnl < 0 ? 'mainred--text' : 'subtext--text'"
                    ><span id="poststatopnl">{{ statposition.oppnl > 0 ? statposition.oppnl : "0.00" }}</span></span
                  >&nbsp;
                  <span class="fs-13" id="poststatopnlclr" :class="statposition.opcpnl > 0 ? 'maingreen--text' : statposition.opcpnl < 0 ? 'mainred--text' : 'subtext--text'"
                    ><span id="poststatopnlc"> {{ statposition.opcpnl > 0 || statposition.opcpnl < 0 ? statposition.opcpnl : "0.00" }}</span
                    >%
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="pb-0">
                <v-list-item-subtitle class="txt-5E6 mb-0 font-weight-medium">Trade Positions</v-list-item-subtitle>
                <v-chip-group row>
                  <v-chip class="mr-2 rounded-lg" color="secgreen" text-color="maingreen">
                    <span class="caption" id="poststatP"> {{ statposition.positive ? statposition.positive.length : "0" }} </span>&nbsp;Positive
                  </v-chip>
                  <v-chip height="16" class="mr-2 rounded-lg" color="secred" text-color="mainred">
                    <span class="caption" id="poststatN"> {{ statposition.negative ? statposition.negative.length : "0" }} </span>&nbsp;Negative
                  </v-chip>
                  <v-chip height="16" class="rounded-lg" color="card-bg" text-color="subtext">
                    <span class="caption" id="poststatC"> {{ statposition.close ? statposition.close.length : "0" }} </span>&nbsp;Closed
                  </v-chip>

                  <!-- <v-chip class="mr-2 rounded-lg" color="#ECF8F1" text-color="#43A833">
                    <span class="caption">{{ statposition.positive ? statposition.positive.length : '0' }}
                      Positive</span>
                  </v-chip>
                  <v-chip height="16" class="mr-2 rounded-lg" color="#FCF3F3" text-color="#FF1717">
                    <span class="caption">{{ statposition.negative ? statposition.negative.length : '0' }}
                      Negative</span>
                  </v-chip>
                  <v-chip class="mr-2 rounded-lg" color="#FFFFFF" text-color="#666666">
                    <span class="caption">{{ statposition.close ? statposition.close.length : '0' }} Closed</span>
                  </v-chip> -->
                </v-chip-group>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <!-- <v-col cols="4" class="d-none">
          <v-card class="px-2 py-2 stat-card rounded-md d-inline-flex pt-2 pb-3" width="100%">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle class="txt-5E6 mb-2 font-weight-medium">Today's High</v-list-item-subtitle>
                <v-list-item-title class="maintext--text font-weight-medium">{{ statposition.hp ? statposition.hp : '0.00'
                  }}
                  <span
                      :class="statposition.chp > 0 ? 'maingreen--text'  : statposition.chp < 0 ? 'mainred--text'  : 'subtext--text">
                      {{ statposition.chp ? statposition.chp : '0.00' }}%
                    </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle class="txt-5E6 mb-2 font-weight-medium">Today's Low</v-list-item-subtitle>
                <v-list-item-title class="maintext--text font-weight-medium">{{ statposition.lp ? statposition.lp : '0.00'
                  }}
                  <span
                      :class="statposition.clp > 0 ? 'maingreen--text'  : statposition.clp < 0 ? 'mainred--text'  : 'subtext--text">
                      {{ statposition.clp ? statposition.clp : '0.00' }}%
                    </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col> -->
      </v-row>

      <v-toolbar flat dense :class="ordertab == 2 ? 'mt-6' : 'my-6'" class="tool-sty pl-4 crd-trn">
        <!-- <p class="title font-weight-bold mb-0">Positions ({{ positiondata && positiondata.length > 0 ? positiondata.length : 0 }})</p> -->

        <v-tabs fixed color="primary" v-model="ordertab" @change="ordertab == 2 ? setPositionGrp() : (statposition = []), setClearstat()">
          <v-tab class="font-weight-bold subtitle-1 mb-0 text-none">Positions ({{ positiondata && positiondata.length > 0 ? positiondata.length : 0 }})</v-tab>
          <v-tab class="font-weight-bold subtitle-1 mb-0 text-none">All Positions ({{ expositiondata && expositiondata.length > 0 ? expositiondata.length : 0 }})</v-tab>
          <v-tab class="font-weight-bold subtitle-1 mb-0 text-none d-none">Group Positions</v-tab>
          <v-tabs-slider color="primary"></v-tabs-slider>
        </v-tabs>

        <!-- <v-tabs fixed color="#0037B7"
          @change="optionChainDataParse(), (ordertab == 1 ? positiontype = 'close' : positiontype = 'open')"
          v-model="ordertab">
          <v-tab class="font-weight-bold subtitle-1 mb-0 text-none">Open Positions (<span>{{ openposition ?
            openposition.length :
            '0'
              }}</span>)
          </v-tab>
          <v-tab class="font-weight-bold subtitle-1 mb-0 text-none">Closed Positions (<span>{{ closeposition ?
            closeposition.length : '0'
              }}</span>)
          </v-tab>
          <v-tabs-slider color="#0037B7"></v-tabs-slider>
        </v-tabs> -->
        <v-spacer></v-spacer>
        <v-text-field v-if="ordertab != 2" style="max-width: 220px" v-model="opensearch" hide-details prepend-inner-icon="mdi-magnify" label="Search" class="rounded-pill mr-4" dense flat solo background-color="secbg"></v-text-field>

        <v-select
          v-if="ordertab != 2"
          style="max-width: 180px"
          v-model="exchtype"
          hide-details
          append-icon="mdi-chevron-down"
          item-text="txt"
          item-value="val"
          prepend-inner-icon="mdi-playlist-check"
          class="rounded-pill"
          dense
          flat
          solo
          background-color="secbg"
          :items="dashitems"
          label="Filter"
        ></v-select>

        <v-btn v-if="ordertab == 0" :disabled="openposition && openposition.length == 0" @click="exitdialog = true" class="elevation-0 rounded-pill font-weight-bold text-none ml-4" color="primary"
          >Exit {{ posdselected.length == positiondata.length ? "all" : posdselected.length > 0 ? posdselected.length : "all" }}</v-btn
        >
        <v-icon :disabled="loading || exloading" class="ml-3 cursor-p" @click="ordertab != 1 ? getPositionbook() : getexPositionbook()" color="maintext" size="24">mdi-reload</v-icon>
      </v-toolbar>

      <v-tabs-items v-model="ordertab" style="z-index: 0">
        <v-tab-item>
          <v-data-table
            show-select
            v-model="posdselected"
            @toggle-select-all="selectAllToggle"
            must-sort
            item-key="tokn"
            :sort-by="['way']"
            :sort-desc="[true]"
            fixed-header
            :loading="loading"
            hide-default-footer
            class="rounded-lg overflow-y-auto"
            style="border-radius: 4px; border: 1px solid var(--outline)"
            height="480px"
            :headers="positionheader"
            :search="opensearch"
            :items="positiondata"
            :items-per-page="-1"
          >
            <template v-slot:body="{items, headers}">
              <tbody v-if="positiondata && positiondata.length > 0">
                <tr :style="`background-color:${item.way != 'open' ? 'var(--secbg)' : ''};`" @click="setPositionrowdata(item)" class="table-row" v-for="(item, o) in items" :key="o">
                  <td>
                    <v-simple-checkbox color="primary" v-if="item.way == 'open'" :value="item.disabled" @click="(item.disabled = !item.disabled), selectAllToggle()"></v-simple-checkbox>
                  </td>
                  <td>
                    <v-chip small :class="item.way == 'open' ? 'table-hov-prd' : ''" text-color="#666" :color="item.way != 'open' ? 'mainbg' : 'secbg'" style="border-radius: 5px; padding: 10px 8px !important">
                      <span class="font-weight-medium subtext--text fs-12">{{ item.s_prdt_ali }}</span>
                    </v-chip>
                  </td>
                  <td class="pos-rlt">
                    <p class="font-weight-medium maintext--text mb-0 table-hov-text ws-p">
                      {{ item.tsym ? item.tsym : "" }}
                      <span class="ml-1 subtext--text fs-10">{{ item.exch ? item.exch : "" }}</span>
                    </p>
                    <div @click.stop class="pos-abs table-hov" style="top: 15px; right: 0">
                      <v-btn @click="switchBus.$emit('menudialog', 'order', item.token, item.exch, item.tsym, 'b')" min-width="20px" color="maingreen" class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small> B </v-btn>
                      <v-btn @click="switchBus.$emit('menudialog', 'order', item.token, item.exch, item.tsym, 's')" min-width="20px" color="mainred" class="px-0 font-weight-bold white--text elevation-0 mr-1" x-small> S </v-btn>
                      <v-btn @click="setSSDtab('chart', item.token, item.exch, item.tsym)" style="border: 1px solid var(--outline)" min-width="20px" color="mainbg" class="px-0 font-weight-bold elevation-0 mr-1" x-small>
                        <v-icon size="18" color="maintext">mdi-chart-line-variant</v-icon>
                      </v-btn>

                      <v-tooltip top color="black">
                        <template v-slot:activator="{on, attrs}">
                          <div v-bind="attrs" v-on="on">
                            <v-btn v-if="item.way == 'open'" @click="setSSDtab('exit-order', item.token, item.exch, item.tsym, item.netqty < 0 ? 'b' : 's', item)" style="border: 1px solid var(--outline)" min-width="20px" color="mainbg" class="px-0 font-weight-bold elevation-0 mr-1" x-small>
                              <v-icon size="18" color="maintext">mdi-close</v-icon>
                            </v-btn>
                          </div>
                        </template>
                        <span>Exit</span>
                      </v-tooltip>

                      <v-tooltip top color="black">
                        <template v-slot:activator="{on, attrs}">
                          <div v-bind="attrs" v-on="on">
                            <v-btn v-if="item.way == 'open'" @click="setPosConvert(item)" style="border: 1px solid var(--outline)" min-width="20px" color="mainbg" class="px-0 font-weight-bold elevation-0 mr-1" x-small>
                              <v-icon size="18" color="maintext">mdi-autorenew</v-icon>
                            </v-btn>
                          </div>
                        </template>
                        <span>Conversion</span>
                      </v-tooltip>

                      <v-menu close-on-click absolute offset-y class="table-menu">
                        <template v-slot:activator="{on, attrs}">
                          <v-btn v-bind="attrs" v-on="on" style="border: 1px solid var(--outline)" min-width="20px" color="mainbg" class="px-0 font-weight-bold elevation-0 mr-1" x-small>
                            <v-icon size="20" color="maintext">mdi-dots-horizontal</v-icon>
                          </v-btn>
                        </template>
                        <v-card class="table-menu-list">
                          <v-list dense>
                            <div v-for="(m, k) in item.way == 'open' ? menulist.o : menulist.c" :key="k">
                              <v-list-item @click="m.type == 'convert' ? setPosConvert(item) : m.type != '' ? setSSDtab(m.type, item.token, item.exch, item.tsym, item.netqty < 0 ? 'b' : 's', item) : setPositionrowdata(item)" class="pl-3 pr-6">
                                <v-list-item-icon class="mr-3 text-center">
                                  <img v-if="m.icon > 2" width="20px" class="pl-1" :src="require(`@/assets/orderbook/${m.icon}.svg`)" />
                                  <v-icon v-else color="#506D84">{{ m.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title class="subline--text font-weight-medium fs-14">{{ m.name }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-divider v-if="m.hr" class="mx-3"></v-divider>
                            </div>
                          </v-list>
                        </v-card>
                      </v-menu>
                    </div>
                  </td>

                  <td>
                    <v-chip
                      small
                      :color="item.netqty > 0 ? 'secgreen' : item.netqty < 0 ? 'secred' : item.way != 'open' ? 'mainbg' : 'secbg'"
                      :text-color="item.netqty > 0 ? 'maingreen' : item.netqty < 0 ? 'mainred' : 'subtext'"
                      :style="`border: 1px solid ${item.netqty > 0 ? '#C1E7BA' : item.netqty < 0 ? '#FFCDCD' : '#DDD'}; border-radius: 5px; padding: 10px 8px !important;`"
                    >
                      <span class="font-weight-medium fs-12">{{ item.netqty > 0 ? `+${item.netqty}` : item.netqty < 0 ? `${item.netqty}` : "0" }}</span>
                    </v-chip>
                  </td>
                  <td class="text-right font-weight-medium">
                    <p class="font-weight-medium maintext--text mb-0">{{ item.avgprc ? Math.abs(item.avgprc).toFixed(2) : "" }}</p>
                  </td>
                  <td class="text-right font-weight-medium">
                    <p class="font-weight-medium maintext--text mb-0">
                      <span :id="`post${item.tokn}ltp`"> {{ item.ltp ? item.ltp : "0.00" }}</span>
                    </p>
                  </td>
                  <td class="text-right font-weight-medium">
                    <p class="font-weight-medium mb-0" :id="`post${item.tokn}mtmclr`" :class="item.mtm > 0 ? 'maingreen--text' : item.mtm < 0 ? 'mainred--text' : 'subtext--text'">
                      <span :id="`post${item.tokn}mtm`"> {{ item.mtm ? item.mtm : "0.00" }}</span>
                    </p>
                  </td>
                  <td class="text-right font-weight-medium">
                    <p class="font-weight-medium mb-0" :id="`post${item.tokn}pnlclr`" :class="item.rpnl > 0 ? 'maingreen--text' : item.rpnl < 0 ? 'mainred--text' : 'subtext--text'">
                      <span :id="`post${item.tokn}rpnl`">{{ item.rpnl ? item.rpnl : "0.00" }}</span>
                    </p>
                  </td>
                  <td class="text-right font-weight-medium">
                    <p class="font-weight-medium mb-0" :id="`post${item.token}pnlcclr`" :class="item.pnlc > 0 ? 'maingreen--text' : item.pnlc < 0 ? 'mainred--text' : 'subtext--text'">
                      <span :id="`post${item.token}pnlchg`">{{ item.pnlc ? item.pnlc : "0.00" }}%</span>
                    </p>
                  </td>
                  <td>
                    <v-chip small :color="item.way != 'open' ? 'mainbg' : 'secbg'" text-color="subtext" style="border: 1px solid var(--outline); border-radius: 5px; padding: 10px 8px !important">
                      <span class="font-weight-medium fs-12">{{ item.daybuyqty != 0 && item.daysellqty != 0 ? item.daybuyqty : item.netqty > 0 ? item.netqty : "0" }}</span>
                    </v-chip>
                  </td>
                  <td>
                    <v-chip small :color="item.way != 'open' ? 'mainbg' : 'secbg'" text-color="subtext" style="border: 1px solid var(--outline); border-radius: 5px; padding: 10px 8px !important">
                      <span class="font-weight-medium fs-12">{{ item.daysellqty != 0 && item.daybuyqty != 0 ? item.daysellqty : item.netqty < 0 ? item.netqty : "0" }}</span>
                    </v-chip>
                  </td>

                  <td class="text-right font-weight-medium">
                    <p class="font-weight-medium maintext--text mb-0">{{ item.totbuyavgprc ? item.totbuyavgprc : "" }}</p>
                  </td>

                  <td class="text-right font-weight-medium">
                    <p class="font-weight-medium maintext--text mb-0">{{ item.totbuyamt ? item.totbuyamt : "" }}</p>
                  </td>

                  <td class="text-right font-weight-medium">
                    <p class="font-weight-medium maintext--text mb-0">{{ item.totsellavgprc ? item.totsellavgprc : "" }}</p>
                  </td>

                  <td class="text-right font-weight-medium">
                    <p class="font-weight-medium maintext--text mb-0">{{ item.totsellamt ? item.totsellamt : "" }}</p>
                  </td>
                  <td class="text-right font-weight-medium">
                    <p class="font-weight-medium maintext--text mb-0">{{ item.upldprc ? item.upldprc : "" }}</p>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <td :colspan="headers.length" class="text-center">
                  <div class="mx-auto py-16 mt-16">
                    <img class="mx-auto" width="80px" :src="require('@/assets/no data folder.svg')" />
                    <h4 class="txt-999 font-weight-regular caption">There is no trading data here yet!</h4>
                  </div>
                </td>
              </tbody>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <v-data-table
            must-sort
            item-key="tokn"
            :sort-by="['way']"
            :sort-desc="[true]"
            fixed-header
            :loading="exloading"
            hide-default-footer
            class="rounded-lg overflow-y-auto"
            style="border-radius: 4px; border: 1px solid var(--outline)"
            height="480px"
            :headers="expositionheader"
            :search="opensearch"
            :items="expositiondata"
            :items-per-page="-1"
          >
            <template v-slot:body="{items, headers}">
              <tbody v-if="expositiondata && expositiondata.length > 0">
                <tr :style="`background-color:${item.way != 'open' ? 'var(--secbg)' : ''};`" class="table-row" v-for="(item, o) in items" :key="o">
                  <td>
                    <p class="font-weight-medium maintext--text mb-0 table-hov-text ws-p">
                      {{ item.tsym ? item.tsym : "" }}
                      <span class="ml-1 subtext--text fs-10">{{ item.exch ? item.exch : "" }}</span>
                    </p>
                  </td>

                  <td>
                    <v-chip
                      small
                      :color="item.netqty > 0 ? 'secgreen' : item.netqty < 0 ? 'secred' : item.way != 'open' ? 'mainbg' : 'secbg'"
                      :text-color="item.netqty > 0 ? 'maingreen' : item.netqty < 0 ? 'mainred' : 'subtext'"
                      :style="`border: 1px solid ${item.netqty > 0 ? '#C1E7BA' : item.netqty < 0 ? '#FFCDCD' : '#DDD'}; border-radius: 5px; padding: 10px 8px !important;`"
                    >
                      <span class="font-weight-medium fs-12">{{ item.netqty > 0 ? `+${item.netqty}` : item.netqty < 0 ? `${item.netqty}` : "0" }}</span>
                    </v-chip>
                  </td>
                  <td class="text-right font-weight-medium">
                    <p class="font-weight-medium maintext--text mb-0">{{ item.avgprc ? Math.abs(item.avgprc).toFixed(2) : "0.00" }}</p>
                  </td>
                  <td class="text-right font-weight-medium">
                    <p class="font-weight-medium maintext--text mb-0">
                      <span :id="`expost${item.tokn}ltp`"> {{ item.ltp ? item.ltp : "0.00" }}</span>
                    </p>
                  </td>
                  <td class="text-right font-weight-medium">
                    <p class="font-weight-medium mb-0" :id="`expost${item.tokn}pnlclr`" :class="item.rpnl > 0 ? 'maingreen--text' : item.rpnl < 0 ? 'mainred--text' : 'subtext--text'">
                      <span :id="`expost${item.tokn}rpnl`">{{ item.rpnl ? item.rpnl : "0.00" }}</span>
                    </p>
                  </td>
                  <td class="text-right font-weight-medium">
                    <p class="font-weight-medium mb-0" :id="`post${item.token}pnlcclr`" :class="item.pnlc > 0 ? 'maingreen--text' : item.pnlc < 0 ? 'mainred--text' : 'subtext--text'">
                      <span :id="`post${item.token}pnlchg`">{{ item.pnlc ? item.pnlc : "0.00" }}%</span>
                    </p>
                  </td>
                  <td>
                    <v-chip small :color="item.way != 'open' ? 'mainbg' : 'secbg'" text-color="subtext" style="border: 1px solid var(--outline); border-radius: 5px; padding: 10px 8px !important">
                      <span class="font-weight-medium fs-12">{{ item.BuyQuantity ? item.BuyQuantity : "0" }}</span>
                    </v-chip>
                  </td>
                  <td>
                    <v-chip small :color="item.way != 'open' ? 'mainbg' : 'secbg'" text-color="subtext" style="border: 1px solid var(--outline); border-radius: 5px; padding: 10px 8px !important">
                      <span class="font-weight-medium fs-12">{{ item.SellQuantity ? item.SellQuantity : "0" }}</span>
                    </v-chip>
                  </td>

                  <td class="text-right font-weight-medium">
                    <p class="font-weight-medium maintext--text mb-0">{{ item.BuyPrice ? item.BuyPrice : "0.00" }}</p>
                  </td>

                  <td class="text-right font-weight-medium">
                    <p class="font-weight-medium maintext--text mb-0">{{ item.BuyValue ? item.BuyValue : "0.00" }}</p>
                  </td>

                  <td class="text-right font-weight-medium">
                    <p class="font-weight-medium maintext--text mb-0">{{ item.SellPrice ? item.SellPrice : "0.00" }}</p>
                  </td>

                  <td class="text-right font-weight-medium">
                    <p class="font-weight-medium maintext--text mb-0">{{ item.SellValue ? item.SellValue : "0.00" }}</p>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <td :colspan="headers.length" class="text-center">
                  <div class="mx-auto py-16 mt-16">
                    <img class="mx-auto" width="80px" :src="require('@/assets/no data folder.svg')" />
                    <h4 class="txt-999 font-weight-regular caption">There is no trading data here yet!</h4>
                  </div>
                </td>
              </tbody>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <v-row class="overflow-hidden">
            <v-col cols="3" class="pr-0 pb-0" style="height: 68vh">
              <div class="overflow-y-auto pr-1" style="height: 50%">
                <v-card @click="setChangesGrp(b)" width="100%" class="elevation-0 pt-3 pb-2 px-3 rounded-lg mb-2 fs-13 overflow-hidden" :id="`${b}mtmclr`" v-for="(a, b, c) in postgroups" :key="c">
                  <span class="font-weight-bold">{{ b }}</span> ({{ a && a.data ? a.data.length : 0 }})
                  <span class="float-right font-weight-bold" :id="`${b}txtclr`" :class="a.text"
                    ><span :id="`${b}mtm`">{{ a && a.mtm ? a.mtm : "0.00" }}</span></span
                  >
                </v-card>
              </div>
              <v-divider></v-divider>
              <div class="overflow-y-auto" style="height: 50%">
                <v-container fill-height class="pt-0">
                  <v-card color="transparent" class="elevation-0 ma-auto text-center">
                    <img src="@/assets/UI greyscale.svg" width="40px" />
                    <p class="font-weight-bold mb-2 fs-14">No position groups found</p>
                    <v-btn @click="grpdialog = true" class="text-capitalize font-weight-bold mb-2 elevation-0 rounded-lg" color="#EEEEEE" small>Create group</v-btn>
                    <p class="font-weight-medium mb-0 fs-12">
                      add the scripts and create your <br />
                      position group
                    </p>
                  </v-card>
                </v-container>
              </div>
            </v-col>
            <v-col cols="9" class="pl-1" style="height: 68vh">
              <div v-if="postgroups[liveposgrp]" class="overflow-y-auto" style="height: 100%">
                <v-card height="50%" width="100%" id="mtmchart" class="crd-trn rounded-lg pt-4 elevation-0"> </v-card>

                <!-- {{ postgroups[liveposgrp].data }} -->
              </div>
            </v-col>
          </v-row>
          <div></div>
        </v-tab-item>
      </v-tabs-items>

      <v-dialog v-model="convertdialog" width="480px">
        <v-card class="pb-6 overflow-hidden" color="cardbg">
          <v-card class="elevation-0 py-4" color="secbg">
            <v-toolbar class="nav-drawer elevation-0 px-2 crd-trn" dense>
              <v-list-item class="px-0">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold fs-16 maintext--text mb-2"
                    >{{ singledata ? singledata.tsym : "" }} <span class="ml-1 subtext--text fs-10">{{ singledata ? singledata.exch : "" }}</span></v-list-item-title
                  >
                  <v-list-item-title class="maintext--text font-weight-bold fs-14 mb-1"
                    ><span id="convpopltp">{{ singledata.ltp ? singledata.ltp : "0.00" }}</span> &nbsp;
                    <span class="fs-12" id="convpopchpclr" :class="singledata.ch > 0 ? 'maingreen--text' : singledata.ch < 0 ? 'mainred--text' : 'subtext--text'">
                      <span id="convpopch">{{ singledata && singledata.ch ? `${singledata.ch}` : "0.00" }} </span>
                      (<span id="convpopchp">{{ singledata && singledata.chp ? `${singledata.chp}` : "0.00" }}</span
                      >%)</span
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-spacer></v-spacer>
              <v-btn :disabled="convertloader" @click="(convertdialog = false), (singledata = [])" small icon>
                <v-icon color="maintext">mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-progress-linear v-if="convertloader" indeterminate></v-progress-linear>
          </v-card>

          <div class="px-6 pt-4 pb-2">
            <p class="font-weight-bold fs-16">Position Conversion</p>
            <v-row no-glutters>
              <v-col cols="5" class="pb-0">
                <p class="font-weight-bold fs-14 mb-2">Convert from</p>
                <v-text-field readonly dense v-model="singledata.s_prdt_ali" background-color="secbg" flat class="rounded-pill" solo></v-text-field>
              </v-col>
              <v-col cols="2" class="pa-0"> </v-col>
              <v-col cols="5" class="pb-0">
                <p class="font-weight-bold fs-14 mb-2">Converting to</p>
                <v-select
                  v-on:keyup.enter="setPosConvert()"
                  dense
                  v-model="convtype"
                  :items="[singledata.s_prdt_ali != 'MIS' || singledata.s_prdt_ali == 'NRML' ? 'MIS' : singledata.exch == 'NSE' || singledata.exch == 'BSE' ? 'CNC' : 'NRML']"
                  append-icon="mdi-chevron-down"
                  background-color="secbg"
                  flat
                  class="rounded-pill"
                  solo
                ></v-select>
              </v-col>
              <v-col cols="5" class="py-0">
                <p class="font-weight-bold fs-14 mb-2">Total Qty</p>
                <v-text-field readonly dense background-color="secbg" :value="singledata.defaultqty" flat class="rounded-pill" solo></v-text-field>
              </v-col>
              <v-col cols="2" class="pa-0"> </v-col>
              <v-col cols="5" class="py-0">
                <p class="font-weight-bold fs-14 mb-2">Converting Qty</p>
                <v-text-field v-on:keyup.enter="setPosConvert()" dense background-color="secbg" v-model="convqty" :placeholder="singledata.netqty" flat class="rounded-pill" solo type="number" hide-spin-buttons min="1" :max="singledata.defaultqty"></v-text-field>
              </v-col>
            </v-row>
            <v-btn @click="setPosConvert()" :disabled="convqty > 0 && convqty <= singledata.defaultqty && convtype ? false : true" :loading="convertloader" color="btnclr" class="text-none rounded-pill elevation-0 btntext--text px-10 mt-4 float-right" height="40px"> Convert </v-btn>
          </div>
        </v-card>
      </v-dialog>

      <v-dialog v-model="grpdialog" hide-overlay width="700px">
        <v-card class="pb-0 pt-1 overflow-hidden" color="cardbg">
          <v-toolbar class="elevation-0 px-2 crd-trn" dense>
            <p class="mb-0 title font-weight-bold">Create group position</p>
            <v-spacer></v-spacer>
            <v-btn @click="grpdialog = false" small icon>
              <v-icon color="maintext">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <div>
            <v-data-table
              show-select
              color="primary"
              v-model="posgrpselected"
              item-key="idx"
              fixed-header
              :loading="loading"
              hide-default-footer
              class="rounded-0 overflow-y-auto"
              style="border-radius: 4px; border: 1px solid var(--outline)"
              height="300px"
              :headers="grpposheader"
              :items="positiondata"
              :items-per-page="-1"
            >
            </v-data-table>
          </div>
        </v-card>
      </v-dialog>
    </div>
    <v-navigation-drawer hide-overlay color="cardbg" fixed temporary app class="pt-2" v-model="positiondrawer" right width="360px">
      <template v-slot:prepend>
        <v-toolbar class="nav-drawer crd-trn" dense>
          <v-icon @click="positiondrawer = false" color="maintext" size="20">mdi-close</v-icon>
          <p class="maintext--text font-weight-bold mb-0 ml-2">Position Details</p>
          <v-spacer></v-spacer>
          <!-- <v-icon color="#666" size="20">mdi-headphones</v-icon>
          <p class="txt-666 font-weight-medium mb-0 ml-2">Need Help ?</p> -->
        </v-toolbar>
      </template>

      <v-list-item class="py-3">
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium maintext--text mb-3"
            >{{ singledata.tsym ? singledata.tsym : "" }} <span class="ml-1 subtext--text fs-10">{{ singledata.exch ? singledata.exch : "" }}</span></v-list-item-title
          >
          <v-list-item-title class="maintext--text font-weight-medium fs-16 mb-1">{{ singledata.ltp ? singledata.ltp : "0.00" }}</v-list-item-title>
          <v-list-item-title :class="singledata.rpnl > 0 ? 'maingreen--text' : singledata.rpnl < 0 ? 'mainred--text' : 'subtext--text'" class="font-weight-medium fs-12">{{ singledata.rpnl ? singledata.rpnl : "0.00" }} ({{ singledata.pnlc ? singledata.pnlc : "0.00" }}%)</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <div class="px-4">
        <!-- <v-list-item class="pt-3 pb-0 px-0">
          <v-list-item-content>
            <v-list-item-subtitle class="txt-666 font-weight-medium fs-13 mb-1">ISIN</v-list-item-subtitle>
            <v-list-item-title class="maintext--text font-weight-bold fs-14">{{ singledata.isin }} 
             </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <div class="pb-6">
          <v-row>
            <v-col cols="6">
              <v-btn @click="setSSDtab('exit-order', singledata.token, singledata.exch, singledata.tsym, singledata.netqty < 0 ? 'b' : 's', singledata)" class="rounded-pill text-none font-weight-bold" block height="40px" outlined><v-icon size="20">mdi-close</v-icon> Exit</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn @click="setPosConvert(singledata)" class="rounded-pill text-none font-weight-bold" block height="40px" outlined><v-icon size="20">mdi-autorenew</v-icon> Conversion</v-btn>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
        <v-list-item-title class="maintext--text font-weight-bold fs-14 py-4"
          >Quantity
          <p class="float-right mb-0">
            <v-chip
              small
              :color="singledata.netqty > 0 ? 'secgreen' : singledata.netqty < 0 ? 'secred' : 'secbg'"
              :text-color="singledata.netqty > 0 ? 'maingreen' : singledata.netqty < 0 ? 'mainred' : 'subtext'"
              :style="`border: 1px solid ${singledata.netqty > 0 ? '#C1E7BA' : singledata.netqty < 0 ? '#FFCDCD' : '#DDD'}; border-radius: 5px; padding: 10px 8px !important;`"
            >
              <span class="font-weight-medium fs-12">{{ singledata.netqty > 0 ? `+${singledata.netqty}` : singledata.netqty < 0 ? `${singledata.netqty}` : "0" }}</span>
            </v-chip>
          </p></v-list-item-title
        >
        <v-divider></v-divider>
        <v-list-item-title class="maintext--text font-weight-bold fs-14 py-4"
          >Avg price
          <p class="float-right mb-0">{{ singledata.avgprc ? singledata.avgprc.toLocaleString() : "0.00" }}</p></v-list-item-title
        >
        <v-divider></v-divider>
        <v-list-item-title class="maintext--text font-weight-bold fs-14 py-4"
          >Last trade price
          <p class="float-right mb-0">{{ singledata.ltp ? singledata.ltp : "0.00" }}</p></v-list-item-title
        >
        <v-divider></v-divider>
        <v-list-item-title class="maintext--text font-weight-bold fs-14 py-4">
          P&L
          <p class="float-right mb-0">
            {{ singledata.rpnl ? singledata.rpnl : "0.00" }} <span class="fs-12" :class="singledata.rpnl > 0 ? 'maingreen--text' : singledata.rpnl < 0 ? 'mainred--text' : 'subtext--text'">({{ singledata.pnlc ? singledata.pnlc : "0.00" }}%)</span>
          </p></v-list-item-title
        >
        <v-divider></v-divider>

        <v-list-item-title class="maintext--text font-weight-bold fs-14 py-4"
          >Product
          <p class="float-right mb-0">{{ singledata.prd ? (singledata.prd == "I" ? "INTRADAY" : singledata.prd == "C" ? "DELIVERY" : "MARKET") : "" }}</p></v-list-item-title
        >
        <v-divider></v-divider>
        <v-list-item-title class="maintext--text font-weight-bold fs-14 py-4"
          >Order type
          <p class="float-right mb-0">{{ singledata.s_prdt_ali ? singledata.s_prdt_ali : "-" }}</p></v-list-item-title
        >
      </div>
      <template v-slot:append>
        <v-divider></v-divider>
        <div class="pa-4">
          <v-btn
            @click="switchBus.$emit('menudialog', 'order', singledata.token, singledata.exch, singledata.tsym, singledata.netqty > 0 ? 'b' : 's')"
            class="rounded-pill text-none font-weight-bold elevation-0"
            :color="singledata.netqty > 0 ? 'secgreen' : singledata.netqty < 0 ? 'secred' : 'secbg'"
            block
            :text-color="singledata.netqty > 0 ? 'maingreen' : singledata.netqty < 0 ? 'mainred' : 'subtext'"
            height="40px"
            :style="`border: 2px solid ${singledata.netqty > 0 ? '#C1E7BA' : singledata.netqty < 0 ? '#FFCDCD' : '#DDD'};`"
          >
            <v-icon size="20">mdi-plus</v-icon> Add</v-btn
          >
        </div>
      </template>
    </v-navigation-drawer>
    <v-dialog v-model="exitdialog" max-width="400">
      <v-card class="rounded-xl elevation-0 text-center pt-8 pb-6 overflow-hidden">
        <img src="@/assets/orderbook/cancel-icon.svg" alt="cancel icon" />
        <p class="font-weight-medium mt-3 fs-22 lh-24 mb-8">
          Do you want to <b>Square Off</b> <br />
          {{ posdselected.length == positiondata.length ? "all" : posdselected.length > 0 ? posdselected.length : "all" }} open Positions?
        </p>
        <v-row class="px-6" no-gluttters>
          <v-col cols="6">
            <v-btn :disabled="orderloader" @click="exitdialog = false" color="#F1F3F8" class="rounded-pill text-none txt-666 font-weight-bold elevation-0" block height="40px">Close</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn :loading="orderloader" @click="setColseposition(0)" color="#000" class="rounded-pill text-none white--text font-weight-bold elevation-0" block height="40px">Exit Order</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import * as echarts from "echarts";
import {mynturl} from "../../apiurl.js";
// import post from '../../posD.json'
import {getQuotesdata, getMPosotion, getEXPosition, getMPosotionCon, getPlaceOrder, getPostPnL} from "../../components/mixins/getAPIdata.js";

import eventBus from "../../eventBus.js";
export default {
  data: () => ({
    uid: null,
    mtoken: null,
    stoken: null,

    exitdialog: false,
    convertdialog: false,
    positiondrawer: false,
    singledata: [],
    loading: false,
    exloading: false,
    orderloader: false,
    dashitems: [
      {val: "all", txt: "All"},
      // { val: 'open', txt: 'Open position' },
      // { val: 'close', txt: 'closed position' },

      {val: "stocks", txt: "Stocks"},
      {val: "fno", txt: "F&O"},
      {val: "comm", txt: "Commodities"},
      {val: "curr", txt: "Currency"},
    ],
    exchtype: "all",
    menulist: {
      o: [
        {name: "Add", icon: "mdi-plus", type: "order"},
        {name: "Conversion", icon: "mdi-autorenew", type: "convert"},
        {name: "Exit", icon: "mdi-close", type: "exit-order", hr: true},
        {name: "Create GTT / GTC", icon: 4, type: "cGTT"},
        {name: "Create Alert", icon: 5, type: "alert"},
        {name: "Market Depth", icon: 6, type: "depth"},
        {name: "Chart", icon: 7, type: "chart", hr: true},
        {name: "Fundamentals", icon: 9, type: "Funda"},
        {name: "Details", icon: 10, type: ""},
      ],
      c: [
        {name: "Create GTT / GTC", icon: 4, type: "cGTT"},
        {name: "Create Alert", icon: 5, type: "alert"},
        {name: "Market Depth", icon: 6, type: "depth"},
        {name: "Chart", icon: 7, type: "chart", hr: true},
        {name: "Fundamentals", icon: 9, type: "Funda"},
        {name: "Details", icon: 10, type: ""},
      ],
    },

    ordertab: 0,
    opensearch: null,
    openposition: [],
    closeposition: [],
    filter: null,
    filters: [],

    positiontype: "open",
    positiondata: [],
    expositiondata: [],
    statposition: [],

    convtype: "",
    convqty: 0,
    convertloader: false,

    posdselected: [],
    posselected: [],

    switchBus: null,

    postgroups: {},
    liveposgrp: null,

    posgrpselected: [],
    grouppos: false,
    grpdialog: false,
  }),
  computed: {
    positionheader() {
      return [
        {text: "Product", value: "s_prdt_ali", sortable: false},
        {text: "Instrument", value: "tsym", sortable: false},
        {text: "Qty", value: "netqty", sortable: false},
        {text: "Avg Price", value: "avg", sortable: false, class: "ws-p", align: "right"},
        {text: "LTP", value: "ltp", sortable: false, align: "right"},
        {text: "MTM", value: "mtm", sortable: false, class: "ws-p", align: "right"},
        {text: "P&L", value: "rpnl", sortable: false, class: "ws-p", align: "right"},
        {text: "P&L %", value: "pnlc", sortable: false, class: "ws-p", align: "right"},
        {text: "Buy Qty", value: "daybuyqty", sortable: false, class: "ws-p"},
        {text: "Sell Qty", value: "daysellqty", sortable: false, class: "ws-p"},
        {text: "Buy Avg", value: "totbuyavgprc", sortable: false, class: "ws-p", align: "right"},
        {text: "Buy Amt", value: "totbuyamt", sortable: false, class: "ws-p", align: "right"},
        {text: "Sell Avg", value: "totsellavgprc", sortable: false, class: "ws-p", align: "right"},
        {text: "Sell Amt", value: "totsellamt", sortable: false, class: "ws-p", align: "right"},
        {text: "Act Avg Price", value: "upldprc", sortable: false, class: "ws-p", align: "right"},
        {text: "", value: "idx", class: " d-none"},
        // { text: '', value: 'way', filter: this.setSeposbook, class: ' d-none' },
        {text: "", value: "exch", filter: this.setExchpos, class: " d-none"},
      ];
    },
    expositionheader() {
      return [
        {text: "Instrument", value: "tsym", sortable: false},
        {text: "Qty", value: "netqty", sortable: false},
        {text: "Avg Price", value: "avg", sortable: false, class: "ws-p", align: "right"},
        {text: "LTP", value: "ltp", sortable: false, align: "right"},
        // {text: "MTM", value: "mtm", sortable: false, class: "ws-p", align: "right"},
        {text: "P&L", value: "rpnl", sortable: false, class: "ws-p", align: "right"},
        {text: "P&L %", value: "pnlc", sortable: false, class: "ws-p", align: "right"},
        {text: "Buy Qty", value: "BuyQuantity", sortable: false, class: "ws-p"},
        {text: "Sell Qty", value: "SellQuantity", sortable: false, class: "ws-p"},
        {text: "Buy Avg", value: "BuyPrice", sortable: false, class: "ws-p", align: "right"},
        {text: "Buy Amt", value: "BuyValue", sortable: false, class: "ws-p", align: "right"},
        {text: "Sell Avg", value: "SellPrice", sortable: false, class: "ws-p", align: "right"},
        {text: "Sell Amt", value: "SellValue", sortable: false, class: "ws-p", align: "right"},
        {text: "", value: "idx", class: " d-none"},
        // { text: '', value: 'way', filter: this.setSeposbook, class: ' d-none' },
        {text: "", value: "exch", filter: this.setExchpos, class: " d-none"},
      ];
    },
    grpposheader() {
      return [
        {text: "Product", value: "s_prdt_ali", sortable: false},
        {text: "Instrument", value: "tsym", sortable: false},
        {text: "Qty", value: "netqty", sortable: false},
        {text: "Avg Price", value: "avgprc", sortable: false, class: "ws-p", align: "right"},
        {text: "Buy Qty", value: "daybuyqty", sortable: false, class: "ws-p"},
        {text: "Sell Qty", value: "daysellqty", sortable: false, class: "ws-p"},
        {text: "Buy Avg", value: "totbuyavgprc", sortable: false, class: "ws-p", align: "right"},
        {text: "Buy Amt", value: "totbuyamt", sortable: false, class: "ws-p", align: "right"},
        {text: "Sell Avg", value: "totsellavgprc", sortable: false, class: "ws-p", align: "right"},
        {text: "Sell Amt", value: "totsellamt", sortable: false, class: "ws-p", align: "right"},
      ];
    },
  },
  created() {
    //
  },
  mounted() {
    let res = sessionStorage.getItem("c3RhdHVz");
    if (res == "dmFsaWR1c2Vy") {
      this.mtoken = sessionStorage.getItem("msession");
      this.uid = sessionStorage.getItem("userid");
      setTimeout(() => {
        this.getPositionbook();
        this.getexPositionbook();
      }, 100);
    } else {
      this.$router.push("/");
    }
    eventBus.$on("web-scoketConn", (data, page) => {
      if (page == "position") {
        this.optionChainDataParse(data);
      }
    });
    eventBus.$on("orderbook-update", (book) => {
      if (book == "port-order") {
        this.getPositionbook();
      }
    });

    this.switchBus = eventBus;
  },
  beforeDestroy() {
    // eventBus.$off('web-scoketConn');
    eventBus.$off("orderbook-update");
  },
  methods: {
    setPositionGrp() {
      this.postgroups = {};
      var groups = [];
      for (var i = 0; i < this.positiondata.length; i++) {
        var g = this.positiondata[i].tsym.split(this.positiondata[i].tsym.match(/(\d{1,2})[a-zA-Z]{3}(\d{2,4})?/g)[0])[0];
        if (this.postgroups && this.postgroups[g]) {
          this.postgroups[g].data.push(this.positiondata[i]);
          this.postgroups[g].tysm.push(this.positiondata[i].tysm);
        } else {
          this.postgroups[`${g}`] = {data: [this.positiondata[i]], color: "secbg", tysm: [this.positiondata[i].tsym]};
        }
        this.positiondata[i]["grp"] = g;
        groups.push(g);
      }
      if (groups && groups.length > 0) {
        this.liveposgrp = groups[0];
        this.ordertab = 2;
        this.setPospnl();
      } else {
        eventBus.$emit("snack-event", 2, "No position found");
      }
    },
    selectAllToggle(props) {
      if (props) {
        if (this.posdselected.length == this.openposition.length) {
          this.posdselected = this.positiondata;
        }
        if (this.posdselected.length == this.positiondata.length) {
          this.posdselected = [];
          for (let p = 0; p < this.positiondata.length; p++) {
            this.positiondata[p]["disabled"] = false;
          }
          this.positiondata.map((p) => (p.disabled = false));
        } else {
          for (let p = 0; p < this.positiondata.length; p++) {
            this.positiondata[p]["disabled"] = props.value;
            if (this.positiondata[p].way == "open" && this.positiondata[p].disabled) {
              this.posdselected.push(this.positiondata[p]);
            } else if (this.positiondata[p].way == "open") {
              this.posdselected.splice(this.posdselected.indexOf(this.positiondata[p]), 1);
            }
          }
          if (this.posdselected.length == this.openposition.length) {
            this.posdselected = this.positiondata;
          }
        }
      } else {
        this.posdselected = this.positiondata.filter((p) => {
          if (p.way == "open" && p.disabled) {
            return true;
          }
        });
        if (this.posdselected.length == this.openposition.length) {
          this.posdselected = this.positiondata;
        }
        if (this.posdselected.length == 0) {
          for (let p = 0; p < this.positiondata.length; p++) {
            this.positiondata[p]["disabled"] = false;
          }
        }
      }

      this.posselected = this.positiondata.filter((p) => {
        if (p.way == "open" && p.disabled) {
          return true;
        }
      });
    },
    setWebsocket(flow, data, is) {
      eventBus.$emit("web-scoketOn", flow, data, is, "position");
    },
    async getPositionbook() {
      this.loading = true;
      let data = await getMPosotion();
      this.positiondata = [];
      if (data && data.a && data.a.length > 0) {
        this.closeposition = data.c;
        this.openposition = data.o;
        this.positiondata = data.a;
        this.setWebsocket("sub", this.positiondata, "pos");
      } else {
        eventBus.$emit("snack-event", 2, data && data.emsg ? data.emsg : data);
      }
      this.loading = false;
    },
    async getexPositionbook() {
      this.exloading = true;
      let data = await getEXPosition();
      this.expositiondata = [];
      if (data && data.length > 0) {
        for (let q = 0; q < data.length; q++) {
          data[q]["idx"] = q;
          // const bAvg = data[q].Exchange == "CDS" || data[q].Exchange == "BCD" ? parseFloat(data[q].totbuyavgprc).toFixed(4) : parseFloat(data[q].totbuyavgprc).toFixed(2);
          // const sAvg = data[q].Exchange == "CDS" || data[q].Exchange == "BCD" ? parseFloat(data[q].totsellavgprc).toFixed(4) : parseFloat(data[q].totsellavgprc).toFixed(2);
          // const bavg = !bAvg ? 0 : bAvg;
          // const savg = !sAvg ? 0 : sAvg;

          if (data[q].netqty == 0) {
            data[q]["way"] = "close";
            data[q]["disabled"] = null;
          } else {
            data[q]["way"] = "open";
            data[q]["disabled"] = false;
          }
          data[q]["avgprc"] = data[q].netavgprc;
          data[q]["tokn"] = data[q].token + "_" + q;
        }
        this.expositiondata = [...data];
        this.setWebsocket("sub", this.expositiondata, "pos");
      } else {
        this.expositiondata = [];
        if (data != 500) {
          eventBus.$emit("snack-event", 2, data && data.emsg ? data.emsg : data);
        }
      }
      this.exloading = false;
    },
    setSeposbook(way) {
      if (!this.positiontype) {
        return true;
      }
      return way === this.positiontype;
    },
    setExchpos(value) {
      if (this.exchtype == "all") {
        if (!this.exchtype) {
          return true;
        }
        return value;
      } else if (this.exchtype == "open" || this.exchtype == "close") {
        //
        return value;
      } else if (this.exchtype == "stocks") {
        if (!this.exchtype && (value == "NSE" || value == "BSE")) {
          return true;
        }
        return value === "NSE" || value === "BSE";
      } else if (this.exchtype == "fno") {
        if (!this.exchtype && value == "NFO") {
          return true;
        }
        return value === "NFO";
      } else if (this.exchtype == "comm") {
        if (!this.exchtype && value == "MCX") {
          return true;
        }
        return value === "MCX";
      } else if (this.exchtype == "curr") {
        if (!this.exchtype && value == "CD") {
          return true;
        }
        return value === "CD";
      } else {
        if (!this.exchtype) {
          return true;
        }
        return value === this.exchtype;
      }
    },
    async setPosConvert(item) {
      if (item) {
        this.singledata = {};
        this.positiondrawer = false;
        this.convertdialog = true;
        this.singledata = item;
        this.singledata["index"] = this.positiondata.indexOf(item);
        this.convqty = Math.abs(this.singledata.netqty);
        this.convtype = null;
        this.singledata["defaultqty"] = Math.abs(this.singledata.netqty);
        this.optionChainDataParse();
      } else if (this.convqty > 0 && this.convqty <= this.singledata.defaultqty && this.convtype) {
        this.convertloader = true;
        let list = {
          uid: this.uid,
          actid: this.uid,
          exch: this.singledata.exch,
          tsym: this.singledata.tsym,
          qty: this.convqty.toString(),
          prd: this.convtype == "CNC" ? "C" : this.convtype == "MIS" ? "I" : this.convtype == "NRML" ? "M" : "",
          prevprd: this.singledata.s_prdt_ali == "CNC" ? "C" : this.singledata.s_prdt_ali == "MIS" ? "I" : this.singledata.s_prdt_ali == "NRML" ? "M" : "",
          trantype: this.singledata.netqty > 0 ? "B" : "S",
          postype: "DAY",
          ordersource: mynturl.source,
        };
        let data = await getMPosotionCon(list);
        if (data.stat == "Ok") {
          eventBus.$emit("snack-event", 1, "Successfully Converted Position");
          this.positiondata[this.singledata.index].s_prdt_ali = this.convtype;
        } else {
          eventBus.$emit("snack-event", 2, data && data.emsg ? data.emsg : data);
        }
        // setTimeout(() => {
        //   this.getPositionbook();
        // }, 100);
        this.convertdialog = false;
        this.convertloader = false;
        this.singledata = {};
      }
    },
    setSSDtab(type, token, exch, tsym, trans, item) {
      if (type == "alert") {
        eventBus.$emit("menudialog", "alert", token, exch, tsym);
      } else if (type == "cGTT") {
        this.positiondrawer = false;
        eventBus.$emit("menudialog", "order-GTT", token, exch, tsym, "b");
      } else if (type == "exit-order") {
        eventBus.$emit("menudialog", type, token, exch, tsym, trans, item);
      } else if (type == "order") {
        eventBus.$emit("menudialog", type, token, exch, tsym, item.netqty < 0 ? "s" : "b", item);
      } else {
        let path = [type, token, exch, tsym];
        this.$router.push({name: "stocks details", params: {val: path}});
      }
    },
    optionChainDataParse(data) {
      var mult = this.positiondata.filter((arr) => {
        return arr.token == data.token;
      });
      if (data && data.token && mult && mult.length > 0) {
        for (let a = 0; a < mult.length; a++) {
          data["tokn"] = mult[a].tokn;
          let w = this.positiondata.findIndex((o) => o.tokn == mult[a].tokn);
          if (w >= 0 && this.positiondata[w].tokn == data.tokn) {
            this.positiondata[w]["ltp"] = Number(data.lp).toFixed(2);
            this.positiondata[w]["val"] = (Number(this.positiondata[w].avgprc) * Math.abs(Number(this.positiondata[w].netqty))).toFixed(2);
            // this.positiondata[w]["vals"] = (Number(this.positiondata[w].avgprc) * Math.abs(Number(this.positiondata[w].netqty))).toFixed(2);
            // this.positiondata[w]["pre"] = (Number(data.lp) * Math.abs(Number(this.positiondata[w].netqty))).toFixed(2);

            let p = this.positiondata[w];
            let qtyis = Number(this.positiondata[w].netqty);
            var cmtm;
            var crpnl;

            if (qtyis == 0) {
              if (p.cfbuyqty != "0") {
                crpnl = ((Number(p.daysellavgprc) * Number(p.daysellqty)) - ((Number(p.cfbuyqty) * Number(p.avgprc)) + (Number(p.daybuyqty) * Number(p.daybuyavgprc))));
              } else if (p.cfsellqty != "0") {
                crpnl = (((Number(p.daysellqty) * Number(p.daysellavgprc)) + (Number(p.cfsellqty) * Number(p.avgprc))) - (Number(p.daybuyavgprc) * Number(p.daybuyqty)));
              } else {
                crpnl = p.rpnl;
              }
            } else {
              var tempunpnl = Number(data.lp) - parseFloat(p.netupldprc || "0");
              crpnl = Number(Number(tempunpnl) * qtyis + Number(p.temprpnl)).toFixed(2);
            }
            if (["NFO", "BFO", "NSE", "BSE"].includes(p.exch)) {
              cmtm = qtyis == 0 ? Number(p.crpnl) : (Number(data.lp) - Number(p.netavgprc)) * qtyis + Number(p.crpnl);
            } else {
              // crpnl = crpnl / Number(p.ls);
              cmtm = qtyis == 0 ? Number(p.crpnl) : (Number(data.lp) - Number(p.netavgprc)) * (Number(p.mult) * qtyis) + Number(p.crpnl);
            }

            this.positiondata[w]["mtm"] = cmtm ? Number(cmtm).toFixed(2) : this.positiondata[w].mtm ? this.positiondata[w].mtm : cmtm;
            this.positiondata[w]["rpnl"] = crpnl ? Number(crpnl).toFixed(2) : this.positiondata[w].rpnl ? this.positiondata[w].rpnl : crpnl;

            var chgper = Number(p.avgprc) > 0 ? ((Number(p.avgprc) - Number(data.lp)) / Number(p.avgprc)) * 100 : 0;
            this.positiondata[w]["pnlc"] = chgper ? Number(chgper).toFixed(2) : "0.00";

            this.positiondata[w]["hp"] = Number(data.high_price) * Math.abs(Number(this.positiondata[w].netqty));
            this.positiondata[w]["lp"] = Number(data.low_price) * Math.abs(Number(this.positiondata[w].netqty));

            let tag = document.getElementById(`post${data.tokn}ltp`);
            if (tag && this.ordertab != 1) {
              document.getElementById(`post${data.tokn}ltp`).innerHTML = this.positiondata[w].ltp;
              document.getElementById(`post${data.tokn}mtm`).innerHTML = this.positiondata[w].mtm;
              eventBus.$emit("color-event", `post${data.tokn}mtmclr`, this.positiondata[w].mtm > 0 ? "maingreen--text" : this.positiondata[w].mtm < 0 ? "mainred--text" : "subtext--text");
              document.getElementById(`post${data.tokn}rpnl`).innerHTML = this.positiondata[w].rpnl;
              eventBus.$emit("color-event", `post${data.tokn}pnlclr`, this.positiondata[w].rpnl > 0 ? "maingreen--text" : this.positiondata[w].rpnl < 0 ? "mainred--text" : "subtext--text");

              let per = document.getElementById(`post${data.tokn}pnlchg`);
              if (per) {
                per.innerHTML = this.positiondata[w].pnlc;
                eventBus.$emit("color-event", `post${data.tokn}pnlcclr`, this.positiondata[w].pnlc > 0 ? "maingreen--text" : this.positiondata[w].pnlc < 0 ? "mainred--text" : "subtext--text");
              }
            }

            if (this.liveposgrp) {
              this.setGrpmtm(this.postgroups[this.positiondata[w].grp].data, this.positiondata[w].grp);
            }
            // this.$set(this.positiondata, w, this.positiondata[w]);
          }
        }

        let x = this.expositiondata.findIndex((o) => o.tokn == data.tokn);
        if (x >= 0 && this.expositiondata[x].tokn == data.tokn) {
          this.expositiondata[x]["ltp"] = Number(data.lp).toFixed(2);
          this.expositiondata[x]["rpnl"] = this.expositiondata[x].netqty && this.expositiondata[x].netqty != 0 ? ((Number(data.lp) - this.expositiondata[x].avgprc) * this.expositiondata[x].netqty).toFixed(2) : (this.expositiondata[x].SellValue - this.expositiondata[x].BuyValue).toFixed(2);
          this.expositiondata[x]["val"] = (this.expositiondata[x].avgprc * Math.abs(this.expositiondata[x].netqty)).toFixed(2);

          let xtag = document.getElementById(`expost${data.tokn}ltp`);
          if (xtag && this.ordertab == 1) {
            document.getElementById(`expost${data.tokn}ltp`).innerHTML = this.expositiondata[x].ltp;
            document.getElementById(`expost${data.tokn}rpnl`).innerHTML = this.expositiondata[x].rpnl;
            eventBus.$emit("color-event", `expost${data.tokn}pnlclr`, this.expositiondata[x].rpnl > 0 ? "maingreen--text" : this.expositiondata[x].rpnl < 0 ? "mainred--text" : "subtext--text");
          }
        }
      }

      var statdata = this.ordertab != 1 ? this.positiondata : this.expositiondata;
      if (statdata && statdata.length > 0) {
        this.statposition["positive"] = statdata.filter((x) => x.mtm > 0);
        this.statposition["negative"] = statdata.filter((x) => x.mtm < 0);

        this.statposition["close"] = statdata.filter((x) => x.netqty == 0);
        this.statposition["open"] = statdata.filter((x) => x.netqty != 0);

        let tradeval = statdata.reduce((acc, o) => acc + (Number(o.val) > 0 ? Number(o.val) : 0), 0);
        this.statposition["tradeval"] = tradeval > 0 || tradeval < 0 ? this.differentView(Math.abs(tradeval)) : 0;

        let oppnl = this.statposition["open"].reduce((acc, o) => acc + (Number(o.rpnl) ? Number(o.rpnl) : 0), 0);
        this.statposition["oppnl"] = oppnl > 0 || oppnl < 0 ? this.differentView(Math.abs(oppnl)) : 0;
        let opcpnl = oppnl > 0 || oppnl < 0 ? ((this.statposition["open"].reduce((acc, o) => acc + (Number(o.rpnl) ? Number(o.rpnl) : 0), 0) / this.statposition["open"].reduce((acc, o) => acc + (Number(o.val) > 0 ? Number(o.val) : 0), 0)) * 100).toFixed(2) : 0;
        opcpnl = opcpnl != "Infinity" ? Number(opcpnl) : (0).toFixed(2);
        this.statposition["opcpnl"] = opcpnl;
        //  let opcpnl = Number(this.statposition.opcpnl);

        let pnl = statdata.reduce((acc, o) => acc + (Number(o.rpnl) ? Number(o.rpnl) : 0), 0);
        this.statposition["pnl"] = pnl > 0 || (pnl < 0 && tradeval > 0) || tradeval < 0 ? this.differentView(pnl) : 0;
        this.statposition["cpnl"] = (pnl > 0 || pnl < 0) && (tradeval > 0 || tradeval < 0) ? ((pnl / tradeval) * 100).toFixed(2) : 0;
        let cpnl = Number(this.statposition.cpnl);

        if (this.ordertab != 1) {
          let mtm = statdata.reduce((acc, o) => acc + (Number(o.mtm) ? Number(o.mtm) : 0), 0);
          this.statposition["mtm"] = mtm ? this.differentView(mtm) : 0;
        } else {
          this.statposition["mtm"] = 0;
        } // this.statposition['hp'] = this.differentView(statdata.reduce((acc, o) => acc + (Number(o.hp) ? Number(o.hp) : 0), 0))
        // this.statposition['lp'] = this.differentView(statdata.reduce((acc, o) => acc + (Number(o.lp) ? Number(o.lp) : 0), 0))

        this.statposition["chp"] = ((statdata.reduce((acc, o) => acc + (Number(o.rpnl) ? Number(o.rpnl) : 0), 0) / statdata.reduce((acc, o) => acc + (Number(o.hp) ? Number(o.hp) : 0), 0)) * 100).toFixed(2);
        this.statposition["clp"] = ((statdata.reduce((acc, o) => acc + (Number(o.rpnl) ? Number(o.rpnl) : 0), 0) / statdata.reduce((acc, o) => acc + (Number(o.lp) ? Number(o.lp) : 0), 0)) * 100).toFixed(2);
        let tag = document.getElementById(`poststatval`);
        if (tag) {
          if (this.ordertab != 1) {
            document.getElementById(`poststatmtm`).innerHTML = this.statposition.mtm;
            eventBus.$emit("color-event", `poststatmtmclr`, this.statposition.mtm > 0 ? "maingreen--text" : this.statposition.mtm < 0 ? "mainred--text" : "subtext--text");
          }
          document.getElementById(`poststatval`).innerHTML = this.statposition.tradeval;

          document.getElementById(`poststatP`).innerHTML = this.statposition.positive.length;
          document.getElementById(`poststatN`).innerHTML = this.statposition.negative.length;
          document.getElementById(`poststatC`).innerHTML = this.statposition.close.length;

          document.getElementById(`poststatpnl`).innerHTML = this.statposition.pnl;
          document.getElementById(`poststatpnlc`).innerHTML = this.statposition.cpnl;
          eventBus.$emit("color-event", `poststatpnlclr`, cpnl > 0 ? "maingreen--text" : cpnl < 0 ? "mainred--text" : "subtext--text");
          eventBus.$emit("color-event", `poststatpnlclr`, cpnl > 0 ? "maingreen--text" : cpnl < 0 ? "mainred--text" : "subtext--text");

          document.getElementById(`poststatopnl`).innerHTML = this.statposition.oppnl;
          document.getElementById(`poststatopnlc`).innerHTML = this.statposition.opcpnl;
          eventBus.$emit("color-event", `poststatopnlclr`, opcpnl > 0 ? "maingreen--text" : opcpnl < 0 ? "mainred--text" : "subtext--text");
          eventBus.$emit("color-event", `poststatopnlclr`, opcpnl > 0 ? "maingreen--text" : opcpnl < 0 ? "mainred--text" : "subtext--text");
        }

        if (this.ordertab == 0 && this.singledata && this.singledata.tokn) {
          let s = this.positiondata.findIndex((o) => o.tokn == this.singledata.tokn);
          if (s >= 0) {
            this.singledata["ltp"] = this.positiondata[s].ltp;
            this.singledata["ch"] = this.positiondata[s].rpnl;
            this.singledata["chp"] = this.positiondata[s].pnlc;

            let tago = document.getElementById(`convpopltp`);
            if (tago) {
              tago.innerHTML = this.singledata.ltp;

              document.getElementById(`convpopch`).innerHTML = this.singledata.ch;
              document.getElementById(`convpopchp`).innerHTML = this.singledata.chp;
              eventBus.$emit("color-event", `convpopchpclr`, this.singledata.ch > 0 ? "maingreen--text" : this.singledata.ch < 0 ? "mainred--text" : "subtext--text");
            }
          }
        }
      }
    },
    differentView(view) {
      return (view && Number(view) && view > 0) || view < 0 ? Number(view).toFixed(2) : "0.00";
    },
    setClearstat() {
      let tag = document.getElementById(`poststatval`);
      if (tag) {
        let mtm = document.getElementById(`poststatmtm`);
        if (this.ordertab != 1 && mtm) {
          mtm.innerHTML = "0.00";
          eventBus.$emit("color-event", `poststatmtmclr`, "subtext--text");
        }
        document.getElementById(`poststatval`).innerHTML = "0.00";

        document.getElementById(`poststatP`).innerHTML = 0;
        document.getElementById(`poststatN`).innerHTML = 0;
        document.getElementById(`poststatC`).innerHTML = 0;

        document.getElementById(`poststatpnl`).innerHTML = "0.00";
        document.getElementById(`poststatpnlc`).innerHTML = "0.00";
        eventBus.$emit("color-event", `poststatpnlclr`, "subtext--text");
        eventBus.$emit("color-event", `poststatpnlclr`, "subtext--text");

        document.getElementById(`poststatopnl`).innerHTML = "0.00";
        document.getElementById(`poststatopnlc`).innerHTML = "0.00";
        eventBus.$emit("color-event", `poststatopnlclr`, "subtext--text");
        eventBus.$emit("color-event", `poststatopnlclr`, "subtext--text");
      }
    },
    setColseposition(i) {
      this.orderloader = true;
      if (i <= this.posselected.length - 1) {
        this.setPlaceorder(i, this.posselected[i]);
      } else if (this.posselected.length == 0 && this.posdselected.length == 0 && i <= this.openposition.length - 1) {
        this.setPlaceorder(i, this.openposition[i]);
      } else {
        eventBus.$emit("snack-event", 1, `All your open positions have been Squared off.`);
        eventBus.$emit("orderbook-update", "orders");
        this.orderloader = false;
        this.exitdialog = false;
        this.posselected = [];
        this.posdselected = [];
        for (let p = 0; p < this.positiondata.length; p++) {
          this.positiondata[p]["disabled"] = false;
        }
      }
    },
    async setPlaceorder(i, raw) {
      let item = {
        uid: this.uid,
        actid: this.uid,
        exch: raw.exch,
        prctyp: "MKT",
        prd: raw.prd,
        prc: "0",
        qty: raw.exch == "MCX" ? String(Math.abs(raw.netqty) * raw.ls) : String(Math.abs(raw.netqty)),
        ret: "DAY",
        tsym: raw.tsym,
        trantype: raw.netqty > 0 ? "S" : "B",
      };
      let data = await getPlaceOrder(item);
      if (data.stat != "Ok") {
        eventBus.$emit("snack-event", 2, data && data.emsg ? data.emsg : data);
        this.orderloader = false;
      }
      setTimeout(() => {
        this.setColseposition(i + 1);
      }, 100);
    },
    async setPositionrowdata(item) {
      this.singledata = {};
      this.positiondrawer = true;
      this.singledata = item;
      this.singledata["quotes"] = await getQuotesdata(`${item.exch}|${item.token}`);
    },
    setGrpmtm(m, i) {
      var mtm = Number(m.reduce((sum, {mtm}) => Number(sum) + Number(mtm), 0));
      this.postgroups[i].color = mtm > 0 ? "secgreen" : mtm < 0 ? "secred" : "secbg";
      this.postgroups[i]["text"] = mtm > 0 ? "maingreen--text" : mtm < 0 ? "mainred--text" : "maintext--text";
      this.postgroups[i]["mtm"] = mtm.toFixed(2);

      let tag = document.getElementById(`${i}mtm`);
      if (tag) {
        tag.innerHTML = this.postgroups[i].mtm;
        eventBus.$emit("color-event", `${i}mtmclr`, this.postgroups[i].color, "div");
        eventBus.$emit("color-event", `${i}txtclr`, this.postgroups[i].text);
      }
      // return mtm.toFixed(2);
    },
    setChangesGrp(value) {
      this.liveposgrp = value;
      this.setPospnl();
    },
    async setPospnl() {
      let data = await getPostPnL(this.postgroups[this.liveposgrp].tysm);
      if (data && data.mtm) {
        var o = [];
        var t = [];
        for (let i = 0; i < data.mtm.length; i++) {
          o.push(new Date(data.mtm[i].name).toLocaleString("en-US", {hour: "numeric", minute: "numeric", hour12: false}));
          t.push(data.mtm[i].value);
        }
        this.setPerformchart(o, t);
      }
    },
    setPerformchart(o, t) {
      var myChart = echarts.init(document.getElementById("mtmchart"));
      var option = {
        title: {
          text: "",
        },
        grid: {
          top: "2%",
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        dataZoom: [
          {
            type: "inside",
            realtime: true,
            start: 30,
            end: 70,
            // xAxisIndex: [0, 1],
          },
        ],
        legend: false,
        tooltip: {
          trigger: "axis",
          // formatter: function (params) {
          //   params = params[0];
          //   var date = new Date(params.name);
          //   return date.toLocaleString() + " : " + params.value;
          //   // return date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() + " : " + params.value;
          // },
          axisPointer: {
            animation: false,
          },
        },
        xAxis: {
          splitLine: {
            show: false,
          },
          boundaryGap: false,
          data: o,
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, "100%"],
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: this.liveposgrp,
            type: "line",
            showSymbol: false,
            data: t,
          },
        ],
      };
      option && myChart.setOption(option);

      window.addEventListener("resize", function () {
        myChart.resize();
      });

      // setInterval(function () {
      //   // for (var i = 0; i < 5; i++) {
      //   // raw.shift();
      //   raw.push(100 * raw.length);
      //   // }
      //   myChart.setOption({
      //     series: [
      //       {
      //         data: raw,
      //       },
      //     ],
      //   });
      // }, 1000);
    },
  },
};
</script>
